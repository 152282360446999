export const SYMBOL_TO_REPRESENTATIVE: { [key: number]: number } = {
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 6,
  '6': 7,
  '7': 8,
  '8': 10,
  '9': 11,
  '10': 12,
  '11': 13,
  '12': 14,
  '13': 15,
  '14': 16,
  '15': 18,
  '16': 19,
  '17': 20,
  '18': 21,
  '19': 22,
  '20': 23,
  '21': 24,
  '22': 28,
  '23': 29,
  '24': 30,
  '25': 32,
  '26': 33,
  '27': 35,
  '28': 37,
  '29': 39,
  '30': 41,
  '31': 42,
  '32': 43,
  '33': 44,
  '34': 45,
  '35': 48,
  '36': 51,
  '37': 53,
  '38': 55,
  '39': 57,
  '40': 60,
  '41': 61,
  '42': 62,
  '43': 64,
  '44': 65,
  '45': 66,
  '46': 67,
  '47': 68,
  '48': 69,
  '49': 70,
  '50': 71,
  '51': 72,
  '52': 73,
  '53': 74,
  '54': 75,
  '55': 79,
  '56': 80,
  '57': 81,
  '58': 83,
  '59': 84,
  '60': 87,
  '61': 88,
  '62': 89,
  '63': 90,
  '64': 92,
  '65': 93,
  '66': 94,
  '67': 95,
  '68': 96,
  '69': 97,
  '70': 98,
  '71': 99,
  '72': 101,
  '73': 102,
  '74': 104,
  '75': 105,
  '76': 107,
  '77': 108,
  '78': 109,
  '79': 110,
  '80': 111,
  '81': 112,
  '82': 113,
  '83': 114,
  '84': 115,
  '85': 116,
  '86': 117,
  '87': 118,
  '88': 119,
  '89': 120,
  '90': 121,
  '91': 122,
  '92': 123,
  '93': 124,
  '94': 125,
  '95': 126,
  '96': 127,
  '97': 128,
  '98': 129,
  '99': 130,
  '100': 131,
  '101': 132,
  '102': 134,
  '103': 135,
  '104': 136,
  '105': 137,
  '106': 138,
  '107': 139,
  '108': 140,
  '109': 142,
  '110': 143,
  '111': 145,
  '112': 148,
  '113': 149,
  '114': 150,
  '115': 151,
  '116': 152,
  '117': 154,
  '118': 155,
  '119': 157,
  '120': 158,
  '121': 159,
  '122': 162,
  '123': 164,
  '124': 166,
  '125': 167,
  '126': 168,
  '127': 169,
  '128': 170,
  '129': 173,
  '130': 175,
  '131': 176,
  '132': 178,
  '133': 180,
  '134': 181,
  '135': 182,
  '136': 183,
  '137': 184,
  '138': 188,
  '139': 189,
  '140': 190,
  '141': 191,
  '142': 192,
  '143': 195,
  '144': 197,
  '145': 198,
  '146': 200,
  '147': 202,
  '148': 203,
  '149': 204,
  '150': 205,
  '151': 206,
  '152': 209,
  '153': 210,
  '154': 211,
  '155': 213,
  '156': 214,
  '157': 215,
  '158': 216,
  '159': 217,
  '160': 218,
  '161': 221,
  '162': 222,
  '163': 223,
  '164': 224,
  '165': 227,
  '166': 231,
  '167': 232,
  '168': 233,
  '169': 236,
  '170': 237,
  '171': 238,
  '172': 240,
  '173': 241,
  '174': 242,
  '175': 243,
  '176': 244,
  '177': 245,
  '178': 246,
  '179': 247,
  '180': 249,
  '181': 252,
  '182': 253,
  '183': 254,
  '184': 255,
  '185': 257,
  '186': 260,
  '187': 261,
  '188': 262,
  '189': 263,
  '190': 264,
  '191': 265,
  '192': 266,
  '193': 267,
  '194': 268,
  '195': 269,
  '196': 270,
  '197': 271,
  '198': 272,
  '199': 275,
  '200': 279,
  '201': 280,
  '202': 281,
  '203': 283,
  '204': 285,
  '205': 286,
  '206': 287,
  '207': 289,
  '208': 290,
  '209': 291,
  '210': 292,
  '211': 293,
  '212': 294,
  '213': 295,
  '214': 297,
  '215': 298,
  '216': 299,
  '217': 301,
  '218': 302,
  '219': 303,
  '220': 304,
  '221': 305,
  '222': 306,
  '223': 307,
  '224': 308,
  '225': 309,
  '226': 310,
  '227': 311,
  '228': 313,
  '229': 314,
  '230': 315,
  '231': 318,
  '232': 319,
  '233': 320,
  '234': 321,
  '235': 322,
  '236': 324,
  '237': 325,
  '238': 328,
  '239': 329,
  '240': 331,
  '241': 332,
  '242': 334,
  '243': 335,
  '244': 336,
  '245': 337,
  '246': 338,
  '247': 339,
  '248': 341,
  '249': 342,
  '250': 343,
  '251': 344,
  '252': 346,
  '253': 347,
  '254': 348,
  '255': 349,
  '256': 351,
  '257': 352,
  '258': 353,
  '259': 354,
  '260': 356,
  '261': 358,
  '262': 359,
  '263': 360,
  '264': 363,
  '265': 364,
  '266': 366,
  '267': 367,
  '268': 368,
  '269': 370,
  '270': 371,
  '271': 372,
  '272': 373,
  '273': 374,
  '274': 375,
  '275': 376,
  '276': 377,
  '277': 379,
  '278': 381,
  '279': 384,
  '280': 385,
  '281': 387,
  '282': 388,
  '283': 389,
  '284': 390,
  '285': 392,
  '286': 393,
  '287': 396,
  '288': 398,
  '289': 399,
  '290': 400,
  '291': 401,
  '292': 404,
  '293': 405,
  '294': 406,
  '295': 409,
  '296': 410,
  '297': 412,
  '298': 414,
  '299': 415,
  '300': 416,
  '301': 418,
  '302': 419,
  '303': 420,
  '304': 421,
  '305': 423,
  '306': 425,
  '307': 426,
  '308': 427,
  '309': 429,
  '310': 431,
  '311': 432,
  '312': 434,
  '313': 435,
  '314': 436,
  '315': 437,
  '316': 438,
  '317': 439,
  '318': 441,
  '319': 442,
  '320': 443,
  '321': 444,
  '322': 445,
  '323': 446,
  '324': 448,
  '325': 449,
  '326': 450,
  '327': 451,
  '328': 452,
  '329': 453,
  '330': 455,
  '331': 456,
  '332': 457,
  '333': 458,
  '334': 459,
  '335': 460,
  '336': 461,
  '337': 462,
  '338': 463,
  '339': 464,
  '340': 465,
  '341': 466,
  '342': 467,
  '343': 468,
  '344': 469,
  '345': 471,
  '346': 472,
  '347': 473,
  '348': 475,
  '349': 476,
  '350': 477,
  '351': 478,
  '352': 480,
  '353': 482,
  '354': 483,
  '355': 485,
  '356': 486,
  '357': 487,
  '358': 488,
  '359': 489,
  '360': 490,
  '361': 491,
  '362': 492,
  '363': 493,
  '364': 494,
  '365': 495,
  '366': 497,
  '367': 498,
  '368': 499,
  '369': 500,
  '370': 501,
  '371': 502,
  '372': 503,
  '373': 504,
  '374': 505,
  '375': 506,
  '376': 507,
  '377': 508,
  '378': 509,
  '379': 510,
  '380': 512,
  '381': 513,
  '382': 514,
  '383': 515,
  '384': 516,
  '385': 517,
  '1111': 1111,
}
