export const SOLUTION_CHAR_TO_SYMBOLS: number[][] = [
  [14, 199, 1],
  [95, 86, 21],
  [237, 86, 21],
  [28, 88, 7],
  [168, 122, 7],
  [17, 152, 17, 31, 7],
  [168, 62, 29, 61, 59, 7],
  [62, 14, 8],
  [151, 21, 16],
  [26, 88, 59, 1],
  [46, 88, 65],
  [46, 88, 122],
  [46, 88, 121],
  [46, 88, 51, 25],
  [46, 88, 51, 38],
  [34, 1, 14],
  [34, 57, 5],
  [34, 1, 62],
  [34, 44, 54],
  [34, 21, 21],
  [34, 35, 57],
  [34, 46, 41],
  [34, 46, 39],
  [34, 17, 88],
  [34, 49, 69],
  [34, 34, 1, 62],
  [34, 9, 1, 88],
  [34, 58, 88],
  [34, 152, 1],
  [34, 107, 25],
  [34, 172, 1],
  [34, 20, 73],
  [34, 20, 88],
  [34, 28, 88],
  [34, 25, 88],
  [34, 107, 21],
  [34, 1, 137],
  [34, 43, 88],
  [34, 16, 118],
  [34, 42, 121],
  [34, 40, 1, 91],
  [34, 133, 21],
  [34, 108, 69],
  [34, 1, 240],
  [34, 71, 71],
  [34, 108, 77],
  [34, 38, 181],
  [34, 35, 278],
  [34, 88, 128],
  [137, 34, 34],
  [34, 103, 103],
  [34, 143, 57],
  [34, 72, 82],
  [34, 4, 202],
  [34, 1, 228],
  [34, 136, 35],
  [34, 31, 198],
  [34, 53, 31, 75],
  [34, 114, 51, 59],
  [34, 88, 4, 88],
  [34, 4, 300],
  [34, 88, 151],
  [34, 61, 35, 100],
  [34, 34, 216],
  [34, 121, 278],
  [34, 237, 57],
  [34, 62, 29, 69],
  [34, 35, 34, 88],
  [34, 168, 122],
  [34, 88, 137],
  [34, 1, 171, 57],
  [34, 61, 31, 75],
  [34, 75, 34, 34],
  [34, 3, 161, 97],
  [34, 106, 34, 82],
  [34, 272, 35],
  [34, 200, 69],
  [34, 75, 230],
  [34, 1, 92, 36, 36],
  [34, 1, 188, 88],
  [34, 89, 17, 88],
  [34, 107, 234],
  [34, 247, 88],
  [34, 3, 161, 142],
  [34, 268, 57],
  [34, 109, 116, 56],
  [34, 121, 29, 88],
  [34, 3, 53, 31, 75],
  [34, 75, 25, 88],
  [34, 136, 152],
  [34, 152, 152],
  [34, 46, 41, 161],
  [34, 107, 35, 73],
  [34, 49, 131, 54],
  [34, 109, 17, 88],
  [34, 132, 171],
  [34, 286, 38],
  [34, 34, 1, 228],
  [34, 237, 118],
  [34, 17, 272],
  [34, 23, 152, 118],
  [34, 252, 52, 59],
  [34, 115, 88, 80],
  [34, 149, 20, 88],
  [34, 217, 1, 120],
  [34, 169, 228],
  [34, 21, 21, 240],
  [34, 17, 88, 161],
  [34, 46, 88, 51, 25],
  [34, 296, 119],
  [34, 312, 57],
  [34, 28, 312],
  [34, 34, 1, 171, 57],
  [34, 99, 34, 152],
  [34, 303, 82],
  [34, 46, 49, 51, 46, 41],
  [34, 103, 80, 137],
  [34, 34, 1, 188, 88],
  [34, 34, 34, 34, 34, 17],
  [34, 136, 4, 29, 69],
  [34, 107, 307],
  [34, 90, 342],
  [34, 4, 89, 98, 185],
  [34, 72, 46, 41, 161],
  [34, 326, 82],
  [34, 132, 312],
  [34, 31, 152, 1, 177],
  [34, 4, 89, 37, 35, 100],
  [34, 21, 1, 4, 312],
  [34, 42, 157, 290],
  [34, 4, 75, 88, 29, 88],
  [34, 229, 108, 131],
  [34, 72, 88, 108, 88],
  [34, 75, 49, 152, 121],
  [34, 71, 71, 71, 1, 38],
  [34, 49, 157, 49, 152],
  [34, 146, 146, 152],
  [34, 247, 309],
  [34, 169, 342],
  [34, 1, 217, 1, 217, 1],
  [34, 263, 312],
  [34, 109, 88, 247, 17],
  [34, 294, 313],
  [34, 273, 19, 16, 120],
  [34, 247, 152, 185],
  [34, 34, 1, 157, 49, 152],
  [34, 34, 1, 88, 34, 88, 34],
  [34, 107, 1, 87, 312],
  [34, 329, 266],
  [34, 72, 14, 73, 1, 88, 82],
  [34, 187, 1, 180, 221],
  [34, 121, 51, 88, 312],
  [34, 217, 217, 217],
  [34, 331, 185, 100],
  [34, 319, 133, 152],
  [34, 330, 106, 196, 21, 21],
  [34, 88, 88, 19, 324, 161],
  [37, 56, 38],
  [121, 1, 38],
  [17, 88, 38],
  [35, 88, 38],
  [42, 225, 5],
  [46, 35, 88, 38],
  [44, 240, 52, 38],
  [17, 88, 38, 17, 88, 38],
  [1, 92, 36, 36],
  [86, 21, 217, 136, 35],
  [154, 42, 225],
  [51, 16, 38, 82],
  [51, 42, 225, 5],
  [51, 152, 46, 35],
  [48, 61, 88],
  [48, 34, 1, 62],
  [48, 143, 57],
  [48, 29, 71, 88],
  [48, 71, 35, 100],
  [48, 46, 89, 88, 237],
  [48, 21, 31, 75, 62, 14, 151],
  [40, 240, 129],
  [90, 1, 88, 108, 39],
  [257, 1, 88, 108, 39],
  [14, 199, 56],
  [1, 77, 57],
  [49, 70, 57],
  [88, 41, 57],
  [43, 111, 57],
  [95, 88, 57],
  [109, 91, 57],
  [49, 131, 57],
  [39, 137, 57],
  [49, 191, 4, 57],
  [121, 278, 57],
  [17, 88, 38, 57],
  [108, 171, 57],
  [37, 138, 5, 57],
  [152, 177, 57],
  [247, 88, 57],
  [29, 108, 90, 57],
  [107, 103, 54, 57],
  [123, 199, 57],
  [237, 86, 21, 57],
  [108, 171, 57, 57],
  [1, 88, 217, 57],
  [107, 132, 88, 57],
  [90, 1, 88, 108, 57],
  [34, 1, 188, 88, 57],
  [179, 19, 235, 57],
  [263, 203, 57],
  [128, 333, 57],
  [296, 217, 1, 57],
  [271, 307, 57],
  [109, 88, 247, 17, 57],
  [44, 5, 57, 344, 57],
  [34, 1, 88, 34, 88, 34, 57],
  [121, 4, 58],
  [118, 59, 58],
  [71, 61, 58],
  [43, 88, 58],
  [1, 128, 73, 58],
  [17, 51, 122, 58],
  [62, 29, 69, 58],
  [42, 225, 58],
  [152, 216, 58],
  [261, 35, 13, 58],
  [258, 161, 58],
  [88, 312, 58],
  [171, 49, 131, 58],
  [180, 180, 51, 58],
  [149, 152, 75, 58],
  [71, 35, 71, 104, 58],
  [128, 333, 58],
  [19, 149, 350, 58],
  [150, 88, 88, 342, 58],
  [43, 37, 56],
  [75, 1, 26, 43, 111],
  [43, 1, 88, 217],
  [152, 1, 151, 21],
  [22, 199, 342],
  [22, 156, 1, 312],
  [23, 169, 228],
  [23, 149, 20, 88],
  [23, 88, 88, 88],
  [46, 34, 34, 17],
  [28, 152, 17],
  [17, 58, 58, 58],
  [17, 303, 82],
  [28, 1, 28],
  [71, 71, 28],
  [33, 1, 54],
  [42, 19, 54],
  [102, 5, 54, 5],
  [49, 131, 54],
  [4, 221, 54],
  [35, 34, 88, 54],
  [102, 297, 54],
  [19, 152, 122],
  [19, 136, 152],
  [19, 240, 121],
  [19, 108, 116, 42, 34],
  [19, 152, 278, 142],
  [19, 149, 350],
  [61, 61, 61, 34, 97],
  [28, 1, 121, 59],
  [168, 51, 59],
  [49, 70, 19, 59],
  [286, 3, 59],
  [229, 108, 17, 260, 59],
  [9, 1, 88],
  [88, 9, 2],
  [88, 31, 7],
  [34, 1, 88],
  [29, 1, 88],
  [33, 1, 88],
  [4, 29, 88],
  [1, 75, 88],
  [88, 34, 1, 14],
  [88, 35, 57],
  [34, 1, 14, 88],
  [88, 46, 39],
  [88, 61, 38],
  [88, 17, 59],
  [88, 10, 75],
  [88, 121, 4],
  [88, 42, 34],
  [88, 122, 7],
  [88, 4, 88],
  [88, 19, 54],
  [88, 4, 71],
  [88, 109, 6],
  [88, 109, 21],
  [29, 71, 88],
  [88, 88, 25],
  [88, 118, 59],
  [88, 141, 1],
  [34, 1, 88, 54],
  [88, 116, 56],
  [88, 43, 111],
  [99, 34, 88],
  [88, 34, 82],
  [88, 88, 39],
  [88, 17, 88],
  [88, 58, 88],
  [88, 99, 50],
  [16, 42, 34, 88],
  [109, 6, 88, 35],
  [88, 46, 170],
  [88, 99, 88],
  [213, 1, 88],
  [88, 34, 137],
  [88, 89, 75],
  [46, 88, 175],
  [88, 88, 88],
  [88, 136, 35],
  [88, 71, 71],
  [88, 34, 1, 88],
  [17, 147, 88],
  [88, 149, 37],
  [18, 88, 25, 88],
  [88, 121, 278],
  [88, 9, 70, 124],
  [88, 88, 142],
  [88, 133, 122],
  [160, 167, 88],
  [88, 46, 88, 65],
  [88, 61, 35, 100],
  [88, 61, 31, 75],
  [106, 187, 88],
  [88, 144, 88],
  [88, 297, 54],
  [88, 271, 38],
  [88, 152, 152],
  [88, 169, 142],
  [88, 84, 278],
  [88, 34, 1, 14, 185],
  [88, 151, 278],
  [46, 49, 29, 35, 88],
  [88, 124, 25, 88],
  [88, 43, 279],
  [88, 180, 180],
  [88, 59, 59, 59, 59],
  [169, 88, 161],
  [88, 283, 54],
  [88, 160, 247],
  [88, 160, 240],
  [46, 49, 51, 91, 88],
  [88, 46, 49, 51, 91],
  [88, 237, 180],
  [88, 107, 99, 88],
  [88, 217, 1, 120],
  [88, 17, 29, 108, 17],
  [294, 108, 88],
  [88, 305, 57],
  [88, 34, 53, 31, 75],
  [88, 213, 1, 88],
  [88, 205, 137],
  [88, 109, 268],
  [88, 90, 266],
  [88, 42, 30, 34, 75],
  [72, 88, 108, 88],
  [88, 152, 43, 34, 13],
  [88, 217, 278],
  [88, 107, 1, 152, 1],
  [71, 88, 88, 120],
  [4, 75, 88, 29, 88],
  [88, 19, 309],
  [88, 300, 43, 5],
  [88, 137, 152, 1],
  [88, 34, 1, 171, 57],
  [88, 285, 142],
  [88, 34, 1, 188, 88],
  [71, 34, 34, 89, 88],
  [88, 71, 61, 221],
  [88, 59, 59, 59, 137],
  [88, 133, 21, 152],
  [88, 294, 4, 73],
  [88, 35, 61, 299],
  [88, 228, 9, 1, 88],
  [88, 116, 1, 274],
  [88, 240, 75, 17],
  [88, 17, 51, 300],
  [88, 199, 31, 198],
  [88, 89, 75, 185],
  [88, 72, 46, 41, 161],
  [88, 305, 42, 34],
  [88, 46, 41, 31, 31, 75],
  [88, 46, 49, 29, 17, 88],
  [72, 88, 108, 58, 88],
  [88, 1, 285, 147],
  [88, 23, 88, 88, 88],
  [88, 132, 312],
  [121, 51, 88, 21, 152],
  [88, 106, 138, 138],
  [88, 133, 152, 124],
  [88, 149, 1, 149, 69],
  [88, 180, 180, 51, 58],
  [88, 250, 314],
  [88, 127, 127, 147, 34],
  [88, 17, 152, 17, 171],
  [88, 151, 21, 42, 29, 71],
  [88, 261, 35, 167],
  [88, 75, 49, 152, 121],
  [88, 72, 88, 108, 88],
  [88, 72, 51, 1, 88, 108],
  [88, 271, 276],
  [88, 229, 108, 131],
  [88, 34, 179, 56, 231],
  [88, 4, 29, 317, 75],
  [88, 137, 137, 203],
  [88, 273, 19, 16, 120],
  [88, 88, 142, 88, 88],
  [129, 88, 88, 88, 88],
  [88, 88, 88, 88, 137],
  [88, 247, 152, 185],
  [88, 284, 73, 34, 34],
  [88, 168, 51, 185, 100],
  [88, 271, 307],
  [88, 17, 74, 312],
  [88, 145, 1, 4, 312],
  [88, 121, 51, 88, 217],
  [88, 107, 185, 221, 25],
  [88, 329, 266],
  [88, 71, 174, 71, 174],
  [88, 46, 88, 51, 307],
  [88, 17, 51, 217, 14, 151],
  [88, 121, 51, 88, 21, 152],
  [88, 42, 217, 180, 152],
  [88, 135, 86, 88, 21, 180],
  [125, 5, 297, 124, 4, 29, 88],
  [88, 88, 19, 324, 161],
  [88, 312, 312, 118],
  [88, 46, 88, 88, 201, 175],
  [88, 168, 222, 297, 82],
  [88, 302, 326, 82],
  [88, 260, 260, 260],
  [88, 88, 1, 4, 312, 88, 88],
  [88, 150, 71, 35, 71, 104, 16, 61],
  [88, 222, 300, 342],
  [207, 51, 88, 88, 201, 175],
  [88, 149, 42, 217, 180, 237],
  [88, 109, 3, 181, 222, 43, 274],
  [4, 89, 98],
  [89, 17, 88],
  [89, 49, 131, 54],
  [89, 147, 88, 2],
  [89, 115, 88, 80],
  [89, 71, 88, 175],
  [89, 88, 312],
  [89, 88, 46, 89, 88],
  [89, 326, 82],
  [20, 3, 71],
  [71, 1, 26],
  [71, 43, 16],
  [71, 46, 41],
  [71, 42, 34],
  [34, 34, 71],
  [71, 46, 39],
  [71, 152, 1],
  [71, 67, 49],
  [71, 88, 25],
  [1, 77, 57, 71],
  [71, 33, 1, 88],
  [71, 1, 152, 1],
  [71, 75, 1, 26],
  [71, 61, 31, 75],
  [71, 213, 9],
  [71, 147, 88, 2],
  [71, 4, 234, 17],
  [71, 108, 17, 104],
  [71, 247, 88],
  [261, 35, 71],
  [121, 51, 88, 71],
  [262, 59, 71],
  [34, 88, 137, 71],
  [71, 152, 1, 151],
  [71, 261, 35, 13],
  [71, 71, 71, 1, 38],
  [71, 23, 152, 118],
  [71, 108, 17, 54, 59],
  [71, 152, 1, 177],
  [71, 133, 152],
  [71, 154, 299],
  [108, 116, 171, 71],
  [71, 149, 34, 1, 88],
  [199, 34, 1, 137, 71],
  [71, 106, 187, 88],
  [71, 51, 332],
  [107, 201, 35, 71],
  [71, 17, 272, 35],
  [71, 89, 34, 221],
  [106, 196, 21, 21, 71],
  [302, 167, 71],
  [46, 88, 122, 104, 71],
  [71, 109, 3, 181, 164],
  [71, 247, 152, 38],
  [309, 62, 14, 8, 71],
  [71, 108, 17, 104, 71],
  [149, 152, 75, 71],
  [124, 108, 290, 71],
  [71, 49, 157, 49, 152],
  [71, 271, 276],
  [124, 20, 73, 278, 71],
  [71, 17, 74, 312],
  [318, 297, 71],
  [109, 88, 247, 17, 71],
  [46, 125, 342, 71],
  [71, 46, 89, 88, 152, 1],
  [28, 51, 1, 35, 34, 88, 59, 71],
  [19, 152, 278, 142, 71],
  [71, 46, 88, 51, 307],
  [217, 217, 217, 71],
  [71, 106, 135, 1, 217, 35],
  [71, 46, 222, 3, 181, 175],
  [71, 19, 237, 237, 151],
  [71, 46, 88, 88, 201, 175],
  [71, 329, 348, 171],
  [72, 51, 1, 88, 108],
  [72, 14, 73, 1, 88, 82],
  [1, 285, 100],
  [108, 285, 151, 111, 35, 100],
  [46, 34, 99, 5],
  [103, 103, 43, 88],
  [150, 222, 51, 103],
  [103, 107, 1, 218, 35],
  [333, 103, 103],
  [75, 1, 26],
  [75, 34, 34],
  [75, 25, 88],
  [75, 71, 71],
  [200, 1, 75],
  [42, 30, 34, 75],
  [165, 57, 75],
  [75, 17, 77],
  [46, 174, 75],
  [192, 72, 75],
  [168, 127, 75],
  [49, 306, 75],
  [75, 133, 152],
  [4, 29, 317, 75],
  [75, 23, 88, 88, 88],
  [75, 342, 82],
  [75, 342, 217],
  [118, 43, 5],
  [46, 13, 118],
  [118, 1, 77],
  [118, 17, 59],
  [118, 4, 75],
  [118, 121, 4],
  [118, 21, 21],
  [118, 62, 14, 8],
  [118, 46, 41],
  [118, 109, 21],
  [118, 42, 121],
  [118, 61, 88],
  [118, 17, 88],
  [118, 108, 77],
  [118, 38, 181],
  [118, 46, 170],
  [118, 133, 21],
  [118, 118, 118],
  [118, 89, 75],
  [16, 42, 34, 118],
  [213, 1, 118],
  [118, 71, 71],
  [199, 121, 4, 118],
  [118, 109, 162],
  [118, 5, 297],
  [118, 88, 10, 75],
  [118, 9, 70, 124],
  [118, 88, 278],
  [118, 19, 16, 120],
  [118, 49, 191, 4],
  [118, 42, 225],
  [260, 59, 118],
  [118, 152, 152],
  [118, 124, 25, 88],
  [199, 255, 118],
  [118, 107, 103, 54],
  [118, 132, 175],
  [118, 172, 152],
  [118, 114, 51, 91],
  [137, 137, 118],
  [118, 46, 88, 51, 25],
  [118, 14, 151, 278],
  [118, 204, 137],
  [118, 195, 215],
  [118, 168, 1, 20, 59],
  [118, 4, 89, 37, 21, 21],
  [118, 285, 185],
  [118, 89, 34, 221],
  [118, 201, 226],
  [118, 107, 307],
  [118, 286, 3, 59],
  [118, 246, 31, 75],
  [118, 263, 203],
  [118, 23, 88, 88, 88],
  [118, 217, 300],
  [118, 46, 49, 29, 17, 88],
  [118, 1, 151, 27, 180],
  [118, 121, 51, 88, 91],
  [118, 305, 161],
  [118, 339, 180],
  [118, 336, 137],
  [118, 72, 88, 108, 88],
  [118, 4, 75, 88, 29, 88],
  [118, 316, 291],
  [118, 106, 138, 138, 127],
  [118, 107, 1, 87, 312],
  [312, 312, 118],
  [46, 13, 88, 278, 118, 105],
  [118, 107, 317, 217],
  [118, 329, 137, 215],
  [20, 3, 122],
  [122, 4, 300],
  [46, 88, 122, 104],
  [109, 122, 122, 122],
  [108, 127, 127, 122],
  [102, 5, 54, 5, 168, 122],
  [286, 37, 37, 51, 122],
  [122, 329, 266],
  [149, 4, 234, 247, 17, 122],
  [319, 300, 300, 122],
  [107, 16, 38],
  [107, 20, 61],
  [107, 1, 151],
  [107, 103, 54],
  [107, 99, 88],
  [107, 1, 152, 1],
  [107, 20, 171],
  [107, 88, 4, 88],
  [107, 247, 17],
  [107, 132, 88],
  [107, 152, 118],
  [107, 121, 278],
  [107, 19, 16, 120],
  [107, 35, 34, 88],
  [107, 34, 1, 240],
  [107, 28, 1, 121, 59],
  [107, 75, 25, 88],
  [107, 1, 218, 35],
  [107, 248, 90],
  [107, 16, 38, 28, 59],
  [107, 1, 88, 217],
  [107, 192, 202],
  [107, 201, 35, 50],
  [107, 149, 152, 75],
  [107, 278, 59, 203],
  [107, 1, 4, 216, 35, 57],
  [107, 192, 114, 51, 59],
  [107, 192, 144, 88],
  [107, 299, 34, 97],
  [107, 198, 312],
  [107, 185, 221, 25],
  [107, 201, 35, 137],
  [107, 317, 217],
  [107, 286, 43, 180],
  [107, 150, 311, 5],
  [107, 75, 49, 152, 121],
  [107, 129, 279, 312],
  [71, 71, 82],
  [192, 278, 82],
  [109, 203, 82],
  [49, 306, 82],
  [114, 73, 88, 82],
  [229, 108, 71, 82],
  [108, 285, 182, 82],
  [121, 29, 88],
  [76, 34, 32],
  [46, 88, 121, 76, 5],
  [76, 262, 31, 1, 221],
  [109, 9, 88],
  [109, 21, 21],
  [109, 17, 88],
  [109, 71, 56],
  [109, 116, 56],
  [109, 143, 21],
  [109, 108, 77],
  [109, 96, 17, 59],
  [109, 121, 278],
  [109, 136, 120],
  [109, 96, 205],
  [109, 133, 152],
  [109, 49, 157, 49, 152],
  [109, 96, 31, 152, 100],
  [109, 3, 181, 222, 43, 274],
  [90, 31, 7],
  [90, 17, 59],
  [90, 34, 1, 14],
  [35, 57, 90],
  [29, 108, 90],
  [34, 83, 90],
  [90, 19, 67],
  [90, 72, 82],
  [90, 35, 34, 88],
  [90, 121, 278],
  [90, 99, 165],
  [90, 61, 35, 100],
  [90, 75, 34, 34],
  [90, 71, 42, 34],
  [90, 107, 35, 73],
  [90, 107, 203],
  [90, 75, 25, 88],
  [90, 152, 21, 21],
  [90, 19, 71, 71],
  [90, 34, 1, 228],
  [90, 109, 116, 56],
  [90, 168, 189],
  [90, 171, 171],
  [90, 204, 42, 34],
  [90, 40, 1, 274],
  [90, 46, 88, 29, 88],
  [90, 302, 167],
  [90, 23, 88, 88, 88],
  [90, 257, 1, 88, 108],
  [90, 34, 1, 62, 1, 4, 312],
  [90, 286, 27, 4, 1, 35],
  [90, 142, 319, 142],
  [90, 237, 118, 362],
  [319, 300, 300, 53],
  [90, 17, 272, 35, 1, 4, 312],
  [90, 88, 88, 19, 324, 161],
  [73, 34, 34],
  [294, 4, 73],
  [136, 35, 111],
  [111, 111, 136, 35],
  [91, 39, 5],
  [37, 20, 91],
  [118, 59, 91],
  [91, 28, 88],
  [107, 35, 91],
  [114, 51, 91],
  [46, 49, 51, 91],
  [4, 234, 91],
  [4, 234, 1, 91],
  [106, 135, 91],
  [121, 51, 88, 91],
  [91, 154, 215],
  [91, 28, 312],
  [91, 1, 88, 217],
  [91, 152, 121, 1, 38],
  [91, 154, 222, 59],
  [149, 152, 75, 91],
  [91, 89, 147, 88, 2],
  [91, 247, 152, 17],
  [91, 46, 49, 147, 152],
  [91, 247, 309],
  [321, 161, 91],
  [71, 71, 82, 240, 91],
  [17, 152, 17, 34, 67],
  [127, 127, 90],
  [127, 127, 147, 34],
  [106, 21, 21],
  [106, 62, 14, 8],
  [106, 172, 1],
  [106, 43, 111],
  [106, 28, 88],
  [106, 34, 82],
  [106, 135, 59],
  [106, 34, 34, 71],
  [106, 4, 71, 119],
  [106, 75, 230],
  [106, 135, 180],
  [106, 312, 57],
  [106, 137, 215],
  [106, 1, 285, 100],
  [106, 5, 297, 124],
  [106, 46, 88, 122, 124],
  [106, 299, 34, 97],
  [106, 72, 88, 108, 82],
  [106, 261, 35, 167],
  [106, 17, 152, 17, 171],
  [106, 121, 29, 88, 161],
  [106, 257, 113, 39, 59],
  [106, 135, 1, 217, 35],
  [106, 271, 217, 221],
  [4, 151, 119],
  [119, 4, 71],
  [46, 174, 77],
  [321, 161, 77],
  [37, 138, 5, 83, 73],
  [49, 191, 4],
  [113, 46, 127],
  [118, 59, 113],
  [113, 48, 113, 48],
  [113, 61, 274],
  [113, 1, 240, 113],
  [321, 161, 113],
  [113, 1, 35, 92, 37, 37, 37, 37],
  [319, 300, 300, 113],
  [165, 165, 114],
  [123, 51, 333],
  [123, 293, 300, 77],
  [1, 77, 97],
  [179, 19, 97],
  [168, 137, 97],
  [271, 38, 97],
  [29, 71, 88, 97],
  [137, 137, 97],
  [72, 88, 108, 97],
  [247, 152, 17, 97],
  [152, 46, 88, 121, 97],
  [96, 46, 41],
  [96, 39, 59],
  [96, 1, 151],
  [96, 72, 82],
  [96, 43, 152],
  [96, 89, 88],
  [96, 127, 100],
  [96, 34, 1, 137],
  [96, 1, 128, 73],
  [96, 71, 151],
  [96, 152, 1, 82],
  [96, 151, 151],
  [96, 34, 34, 151],
  [96, 283, 54],
  [96, 240, 129],
  [96, 31, 152, 100],
  [96, 33, 17, 215],
  [96, 46, 49, 51, 46, 41],
  [96, 90, 1, 38, 161],
  [96, 90, 1, 4, 71, 161],
  [96, 17, 222, 1, 185],
  [96, 84, 278, 161],
  [96, 90, 1, 300, 161],
  [5, 57, 185],
  [46, 13, 185],
  [185, 46, 13],
  [1, 75, 185],
  [34, 1, 14, 185],
  [35, 57, 185],
  [118, 59, 185],
  [185, 1, 151],
  [185, 20, 91],
  [61, 88, 185],
  [185, 61, 88],
  [42, 114, 185],
  [103, 54, 185],
  [185, 59, 71],
  [185, 71, 61],
  [185, 138, 5],
  [185, 72, 82],
  [185, 121, 1, 38],
  [73, 105, 185],
  [118, 88, 185],
  [185, 20, 180],
  [16, 42, 34, 185],
  [185, 4, 221],
  [89, 75, 185],
  [185, 99, 88],
  [185, 29, 1, 88],
  [185, 67, 88],
  [136, 35, 185],
  [185, 34, 1, 88],
  [62, 29, 69, 185],
  [185, 121, 278],
  [185, 35, 88, 38],
  [185, 49, 191, 4],
  [185, 152, 67],
  [185, 31, 198],
  [185, 17, 51, 122],
  [185, 144, 88],
  [34, 3, 161, 185],
  [34, 42, 121, 185],
  [185, 46, 34, 34, 17],
  [185, 237, 122],
  [185, 71, 108, 17],
  [185, 28, 152, 17],
  [185, 132, 171],
  [185, 29, 71, 88],
  [185, 107, 103, 54],
  [147, 88, 2, 185],
  [185, 152, 177],
  [185, 29, 108, 46, 13],
  [185, 177, 185],
  [185, 136, 152],
  [185, 106, 28, 88],
  [185, 20, 73, 278],
  [185, 128, 4, 89, 37],
  [137, 215, 185],
  [185, 90, 266],
  [185, 240, 129],
  [149, 20, 88, 185],
  [185, 152, 235],
  [185, 312, 57],
  [185, 237, 180],
  [237, 180, 185],
  [34, 1, 171, 57, 185],
  [185, 34, 1, 171, 57],
  [185, 31, 152, 100],
  [247, 152, 185],
  [185, 88, 88, 1, 26],
  [168, 51, 185, 100],
  [185, 22, 75, 34, 34],
  [213, 1, 88, 185],
  [185, 222, 46, 41],
  [185, 34, 1, 188, 88],
  [185, 163, 293],
  [19, 240, 121, 185],
  [185, 263, 137],
  [288, 39, 59, 185],
  [108, 127, 127, 185],
  [61, 278, 21, 21, 185],
  [185, 149, 46, 13, 128],
  [185, 89, 34, 221],
  [185, 17, 272, 35],
  [149, 152, 75, 185],
  [185, 61, 61, 61, 34, 97],
  [185, 302, 167],
  [23, 149, 20, 88, 185],
  [185, 352, 58],
  [185, 154, 222, 59],
  [185, 198, 312],
  [165, 165, 114, 185],
  [185, 297, 146],
  [96, 34, 34, 151, 185],
  [271, 217, 185, 185],
  [109, 203, 82, 185],
  [106, 196, 14, 185, 100],
  [185, 106, 345],
  [213, 28, 1, 121, 185],
  [35, 34, 88, 42, 34, 185],
  [331, 185, 100],
  [34, 136, 4, 29, 69, 185],
  [185, 49, 157, 49, 152],
  [185, 293, 103, 80],
  [48, 359, 185],
  [185, 342, 180],
  [185, 17, 74, 312],
  [185, 247, 309],
  [67, 88, 285, 185],
  [185, 336, 179],
  [185, 152, 46, 88, 121],
  [107, 132, 222, 185],
  [185, 247, 152, 185],
  [185, 213, 1, 88, 185],
  [185, 149, 352],
  [318, 297, 185],
  [185, 42, 29, 71, 57, 164],
  [106, 34, 342, 185],
  [185, 4, 29, 317, 75],
  [185, 46, 89, 88, 237],
  [199, 135, 92, 36, 36, 185],
  [185, 329, 266],
  [96, 90, 1, 4, 71, 161, 185],
  [185, 150, 222, 51, 215],
  [185, 305, 57, 312],
  [185, 46, 222, 3, 181, 175],
  [272, 121, 4, 300, 185],
  [185, 34, 34, 147, 347],
  [185, 215, 215, 342],
  [185, 260, 260, 260],
  [72, 51, 1, 88, 108, 16, 42, 34, 185],
  [319, 300, 300, 185],
  [147, 88, 1],
  [213, 28, 1, 121],
  [1, 285, 147],
  [17, 152, 17, 147],
  [261, 35, 13, 147],
  [88, 260, 147],
  [17, 147, 342],
  [299, 34, 97, 147],
  [271, 1, 88, 108, 147],
  [299, 342, 147],
  [17, 147, 217, 136, 35],
  [169, 46, 41],
  [169, 88, 194],
  [160, 35, 57],
  [160, 19, 59],
  [160, 21, 21],
  [160, 19, 54],
  [160, 17, 59],
  [160, 121, 4],
  [160, 44, 54],
  [160, 62, 14, 8],
  [160, 39, 59],
  [160, 50, 17],
  [160, 46, 39],
  [160, 67, 49],
  [160, 61, 88],
  [160, 43, 111],
  [160, 172, 1],
  [160, 28, 88],
  [160, 76, 58],
  [160, 49, 69],
  [160, 34, 1, 62],
  [160, 88, 57],
  [160, 73, 52],
  [160, 20, 37, 5],
  [160, 31, 117],
  [160, 127, 58],
  [160, 43, 88],
  [160, 116, 56],
  [160, 61, 77],
  [160, 57, 88],
  [160, 1, 165],
  [160, 95, 88],
  [160, 83, 73],
  [160, 72, 88],
  [160, 14, 199, 1],
  [160, 136, 35],
  [49, 131, 160],
  [160, 36, 129],
  [160, 133, 26],
  [160, 108, 77],
  [160, 34, 1, 88],
  [34, 1, 88, 160],
  [160, 72, 82],
  [160, 21, 152],
  [165, 57, 160],
  [160, 107, 118],
  [160, 38, 181],
  [160, 107, 35, 7],
  [160, 61, 31, 75],
  [160, 9, 70, 124],
  [160, 71, 34, 34],
  [160, 19, 16, 120],
  [160, 4, 71, 119],
  [160, 42, 225],
  [160, 75, 34, 34],
  [160, 144, 88],
  [160, 89, 137],
  [160, 88, 151],
  [160, 121, 278],
  [160, 152, 71],
  [160, 88, 278],
  [160, 200, 69],
  [160, 34, 67, 88],
  [160, 169, 142],
  [160, 19, 71, 71],
  [160, 49, 131, 54],
  [160, 34, 1, 14, 185],
  [160, 167, 42, 34],
  [160, 114, 51, 91],
  [160, 34, 1, 228],
  [160, 168, 51, 59],
  [160, 28, 152, 17],
  [121, 51, 88, 160],
  [160, 43, 279],
  [160, 46, 34, 99, 5],
  [160, 109, 116, 56],
  [160, 168, 189],
  [160, 71, 71, 28],
  [160, 46, 88, 121],
  [160, 168, 137],
  [160, 51, 35, 137],
  [282, 57, 160],
  [160, 247, 118],
  [160, 107, 35, 73],
  [160, 75, 230],
  [160, 136, 152],
  [160, 43, 293],
  [160, 49, 305],
  [160, 257, 1, 75],
  [160, 258, 137],
  [160, 200, 1, 75],
  [160, 149, 217],
  [160, 152, 1, 151],
  [160, 67, 286],
  [160, 88, 260],
  [160, 152, 1, 177],
  [160, 42, 30, 34, 75],
  [160, 109, 268],
  [160, 90, 266],
  [160, 21, 21, 240],
  [160, 237, 180],
  [160, 152, 43, 34, 13],
  [160, 51, 302],
  [160, 168, 1, 20, 59],
  [160, 88, 312],
  [160, 303, 82],
  [160, 294, 4, 73],
  [160, 59, 5, 5, 274],
  [160, 278, 279],
  [160, 316, 90],
  [160, 286, 3, 59],
  [160, 46, 88, 29, 88],
  [160, 106, 187, 88],
  [160, 287, 39, 59],
  [160, 149, 34, 1, 88],
  [160, 34, 1, 188, 88],
  [160, 149, 34, 1, 137],
  [160, 107, 35, 236],
  [160, 89, 147, 88, 2],
  [160, 109, 108, 77],
  [160, 46, 127, 181, 17],
  [160, 46, 49, 29, 17, 88],
  [160, 90, 342],
  [106, 138, 138, 160],
  [71, 108, 17, 104, 160],
  [160, 149, 152, 75],
  [149, 152, 75, 160],
  [160, 299, 240],
  [160, 321, 161],
  [160, 180, 180, 51, 58],
  [160, 121, 51, 88, 148],
  [160, 136, 278, 161],
  [160, 71, 71, 71, 1, 38],
  [160, 261, 35, 167],
  [160, 278, 142, 180],
  [160, 247, 309],
  [160, 84, 278, 161],
  [160, 257, 113, 39, 59],
  [160, 75, 49, 152, 121],
  [160, 339, 180],
  [160, 162, 162, 162],
  [160, 317, 217],
  [160, 154, 260, 59],
  [160, 111, 111, 136, 35],
  [160, 229, 108, 131],
  [160, 71, 294, 182],
  [160, 213, 1, 88, 185],
  [160, 34, 1, 88, 34, 88, 34],
  [160, 46, 125, 342],
  [160, 329, 217],
  [160, 271, 58],
  [160, 46, 89, 88, 152, 1],
  [160, 222, 71, 108, 17],
  [302, 56, 39, 59, 160],
  [160, 121, 51, 88, 217],
  [160, 88, 88, 88, 137],
  [150, 43, 88, 161, 160],
  [160, 34, 179, 56, 231],
  [160, 42, 19, 35, 319],
  [109, 88, 247, 17, 160],
  [160, 271, 307],
  [160, 107, 278, 59, 203],
  [160, 21, 31, 75, 62, 14, 151],
  [160, 107, 185, 221, 25],
  [160, 336, 99, 88],
  [160, 49, 306, 75, 124],
  [160, 106, 135, 1, 217, 35],
  [160, 222, 61, 278, 21, 21],
  [160, 371, 161],
  [160, 331, 185, 100],
  [137, 37, 37, 137, 75, 160],
  [160, 131, 131, 302],
  [160, 336, 305, 49],
  [160, 46, 88, 88, 201, 175],
  [160, 42, 88, 21, 21, 42, 225, 5],
  [160, 90, 342, 29, 35, 88],
  [160, 260, 260, 260],
  [319, 300, 300, 160],
  [160, 366, 342],
  [160, 286, 37, 37, 51, 311],
  [160, 215, 215, 342, 59],
  [160, 262, 31, 1, 222, 311],
  [46, 41, 161],
  [1, 151, 161],
  [17, 88, 161],
  [46, 35, 37, 161],
  [31, 198, 161],
  [72, 46, 41, 161],
  [34, 1, 137, 161],
  [133, 122, 161],
  [121, 29, 88, 161],
  [136, 278, 161],
  [46, 88, 122, 161],
  [150, 43, 88, 161],
  [46, 88, 29, 88, 28, 59],
  [305, 161, 1, 151],
  [46, 49, 29, 17, 88, 161],
  [303, 46, 41, 161],
  [34, 1, 88, 34, 88, 34, 161],
  [321, 161, 143, 21],
  [129, 179, 129],
  [293, 50, 17],
  [34, 1, 137, 50, 17],
  [261, 35, 13, 50, 17],
  [17, 152, 17, 34, 50, 17],
  [261, 35, 167],
  [247, 137, 167],
  [127, 127, 1, 127, 127, 12, 167],
  [46, 41, 34, 50],
  [46, 41, 31, 117],
  [46, 49, 51, 46, 41],
  [46, 41, 31, 176],
  [46, 41, 31, 14, 151],
  [46, 41, 31, 235],
  [46, 41, 31, 109, 21],
  [46, 41, 31, 31, 75],
  [46, 41, 31, 75, 25, 88],
  [46, 41, 31, 261, 35],
  [152, 43, 16],
  [152, 44, 54],
  [152, 21, 21],
  [152, 57, 88],
  [152, 1, 151],
  [152, 72, 82],
  [152, 121, 1, 38],
  [1, 61, 61, 1, 152],
  [152, 4, 29, 88],
  [152, 107, 118],
  [152, 42, 225],
  [152, 144, 88],
  [152, 31, 198],
  [152, 19, 16, 120],
  [108, 229, 152],
  [152, 46, 88, 121],
  [152, 137, 167],
  [152, 132, 171],
  [152, 152, 152],
  [31, 75, 88, 152],
  [152, 252, 52, 59],
  [152, 51, 302],
  [152, 51, 302],
  [152, 133, 152],
  [152, 168, 1, 20, 59],
  [152, 247, 152],
  [220, 152, 1, 177],
  [297, 146, 152, 1],
  [302, 167, 152],
  [149, 152, 75, 152],
  [152, 136, 35, 199],
  [152, 342, 182],
  [19, 237, 237, 152],
  [152, 71, 71, 71, 1, 38],
  [152, 168, 51, 185, 100],
  [152, 222, 133, 152],
  [152, 152, 136, 35, 199],
  [152, 106, 135, 1, 217, 35],
  [152, 43, 34, 13],
  [154, 222, 59],
  [49, 157, 49, 152],
  [131, 131, 152],
  [154, 260, 59],
  [34, 1, 157, 49, 152],
  [171, 54, 59],
  [108, 116, 171],
  [171, 108, 75],
  [5, 297, 171],
  [46, 13, 171, 4, 71],
  [17, 152, 17, 171],
  [261, 35, 171],
  [171, 149, 51, 1, 307],
  [137, 46, 38],
  [137, 35, 61],
  [137, 19, 59],
  [137, 21, 21],
  [137, 46, 41],
  [137, 17, 59],
  [137, 17, 88],
  [137, 88, 57],
  [58, 88, 137],
  [137, 43, 88],
  [199, 63, 137],
  [137, 152, 1],
  [137, 22, 101],
  [137, 25, 88],
  [137, 102, 54],
  [151, 21, 137],
  [137, 46, 91],
  [137, 36, 129],
  [137, 46, 170],
  [137, 4, 202],
  [137, 99, 88],
  [17, 147, 137],
  [103, 80, 137],
  [137, 71, 71],
  [137, 38, 181],
  [137, 42, 19, 54],
  [137, 22, 129],
  [107, 118, 137],
  [28, 152, 137],
  [137, 29, 1, 88],
  [59, 59, 59, 137],
  [137, 1, 152, 1],
  [137, 72, 88],
  [137, 62, 29, 69],
  [137, 152, 67],
  [199, 57, 5, 5, 137],
  [137, 4, 71, 119],
  [137, 31, 198],
  [137, 165, 124],
  [137, 247, 17],
  [137, 33, 1, 194],
  [34, 3, 161, 137],
  [137, 121, 278],
  [137, 144, 88],
  [237, 57, 137],
  [137, 61, 35, 100],
  [137, 49, 191, 4],
  [137, 147, 77],
  [137, 89, 137],
  [137, 84, 128],
  [137, 137, 39, 5],
  [137, 240, 91],
  [137, 261, 35],
  [137, 152, 21, 21],
  [137, 200, 69],
  [137, 107, 203],
  [137, 171, 171],
  [121, 51, 88, 137],
  [137, 147, 147],
  [137, 137, 137],
  [137, 28, 152, 17],
  [137, 107, 234],
  [137, 75, 25, 88],
  [137, 17, 272],
  [137, 28, 1, 121, 59],
  [137, 260, 124],
  [137, 152, 1, 177],
  [137, 40, 1, 274],
  [137, 165, 57, 75],
  [137, 137, 14, 151],
  [137, 222, 46, 41],
  [137, 17, 29, 108, 17],
  [137, 28, 312],
  [137, 88, 260],
  [229, 294, 35],
  [137, 26, 88, 59, 1],
  [137, 21, 21, 240],
  [137, 60, 59, 221],
  [137, 297, 146],
  [137, 34, 1, 171, 57],
  [137, 5, 297, 124],
  [137, 107, 35, 34, 88],
  [137, 200, 237],
  [137, 46, 49, 51, 46, 41],
  [137, 107, 35, 236],
  [137, 342, 17],
  [137, 315, 82],
  [180, 180, 51, 137],
  [137, 44, 5, 57, 217],
  [137, 154, 299],
  [137, 46, 88, 29, 88],
  [137, 201, 226],
  [137, 4, 234, 182],
  [137, 34, 1, 188, 88],
  [137, 73, 312],
  [137, 46, 88, 122, 124],
  [192, 278, 82, 137],
  [137, 200, 286],
  [127, 127, 240, 137],
  [137, 96, 34, 34, 151],
  [137, 37, 37, 137, 75],
  [137, 263, 203],
  [137, 23, 88, 88, 88],
  [137, 247, 152, 17],
  [137, 149, 152, 75],
  [137, 294, 249],
  [137, 342, 180],
  [137, 229, 108, 131],
  [137, 72, 88, 108, 82],
  [137, 149, 1, 149, 69],
  [137, 49, 306, 82],
  [137, 324, 161],
  [137, 162, 162, 162],
  [137, 4, 75, 88, 29, 88],
  [137, 258, 29, 35, 88],
  [137, 257, 1, 88, 108],
  [137, 127, 127, 147, 34],
  [137, 42, 157, 290],
  [137, 124, 20, 73, 278],
  [137, 135, 1, 217, 35],
  [137, 46, 89, 88, 152, 1],
  [137, 240, 46, 41, 161],
  [137, 121, 51, 88, 217],
  [137, 34, 1, 157, 49, 152],
  [137, 34, 1, 88, 34, 88, 34],
  [137, 149, 51, 1, 307],
  [137, 107, 1, 87, 312],
  [137, 107, 185, 221, 25],
  [137, 262, 31, 1, 221],
  [137, 299, 342],
  [137, 22, 156, 1, 312],
  [137, 42, 217, 180, 152],
  [137, 336, 88, 4, 88],
  [137, 284, 297, 54],
  [137, 72, 222, 312],
  [137, 106, 72, 88, 108, 82],
  [137, 312, 312, 118],
  [137, 336, 305, 49],
  [137, 150, 88, 88, 342],
  [137, 262, 31, 1, 222, 311],
  [16, 42, 34],
  [167, 42, 34],
  [35, 34, 88, 42, 34],
  [261, 35, 42, 34],
  [344, 42, 34],
  [72, 203, 42, 34],
  [152, 43, 34, 13, 42, 34],
  [363, 42, 34],
  [18, 88, 25, 88, 42, 34],
  [23, 88, 88, 88, 42, 34],
  [34, 1, 88, 299, 42, 34],
  [286, 27, 4, 1, 35, 42, 34],
  [150, 88, 88, 342, 42, 34],
  [1, 83, 151],
  [151, 17, 59],
  [141, 1, 151],
  [151, 213, 1, 87],
  [19, 237, 237, 151],
  [4, 234, 151, 114, 51, 91],
  [143, 41, 59],
  [143, 61, 88],
  [143, 43, 152],
  [143, 4, 202],
  [143, 17, 272],
  [143, 147, 147],
  [143, 31, 152, 1, 177],
  [143, 34, 1, 88, 34, 88, 34],
  [143, 107, 1, 87, 312],
  [143, 34, 34, 147, 347],
  [238, 39, 59],
  [288, 39, 59],
  [37, 138, 5, 39, 59],
  [72, 51, 1, 39, 39, 59],
  [109, 136, 35, 39, 59],
  [286, 71, 39, 59],
  [247, 290, 39, 59],
  [23, 88, 88, 88, 39, 59],
  [31, 198, 84, 128],
  [217, 21, 21],
  [46, 88, 51, 162],
  [162, 180, 180],
  [162, 296, 119],
  [46, 13, 254],
  [163, 35, 57],
  [163, 89, 75],
  [163, 107, 118],
  [163, 1, 128, 73],
  [163, 180, 180],
  [163, 123, 199],
  [163, 89, 34, 221],
  [199, 75, 5],
  [199, 35, 57],
  [199, 4, 75],
  [199, 16, 38],
  [199, 51, 38],
  [199, 41, 59],
  [199, 121, 4],
  [199, 107, 21],
  [199, 25, 88],
  [199, 61, 88],
  [199, 57, 88],
  [199, 17, 88],
  [199, 28, 88],
  [199, 35, 88],
  [199, 88, 38],
  [199, 89, 34],
  [199, 113, 61],
  [199, 71, 61],
  [199, 89, 35],
  [199, 4, 183],
  [199, 43, 111],
  [199, 109, 21],
  [199, 143, 57],
  [199, 164, 38],
  [199, 99, 88],
  [199, 29, 1, 88],
  [199, 136, 35],
  [199, 43, 37, 56],
  [199, 95, 88],
  [199, 34, 1, 88],
  [199, 33, 176],
  [199, 84, 128],
  [199, 160, 167],
  [199, 61, 35, 100],
  [199, 164, 88],
  [199, 5, 297],
  [199, 168, 122],
  [199, 35, 34, 88],
  [199, 31, 198],
  [199, 114, 51, 59],
  [199, 1, 128, 73],
  [199, 121, 278],
  [199, 151, 87],
  [199, 4, 151, 119],
  [199, 88, 278],
  [199, 49, 191, 4],
  [199, 109, 91, 57],
  [199, 19, 71, 71],
  [199, 46, 34, 99, 5],
  [199, 14, 199, 56],
  [199, 89, 17, 88],
  [199, 46, 88, 121],
  [199, 128, 217],
  [199, 137, 167],
  [199, 37, 20, 278],
  [199, 261, 35],
  [199, 137, 137],
  [199, 121, 29, 88],
  [199, 28, 1, 121, 59],
  [199, 43, 279],
  [199, 107, 203],
  [199, 169, 142],
  [199, 137, 35, 61],
  [199, 180, 180],
  [199, 46, 41, 34, 50],
  [199, 168, 189],
  [199, 34, 1, 228],
  [199, 168, 4, 71],
  [199, 51, 35, 137],
  [199, 46, 88, 122],
  [199, 314, 1],
  [199, 152, 21, 21],
  [199, 75, 230],
  [199, 147, 147],
  [199, 1, 75, 185],
  [199, 132, 171],
  [199, 42, 30, 34, 75],
  [199, 133, 152],
  [199, 213, 1, 88],
  [199, 34, 1, 171, 57],
  [199, 73, 52, 137],
  [199, 106, 135, 59],
  [199, 137, 152, 1],
  [199, 17, 51, 122, 58],
  [199, 109, 268],
  [199, 277, 88],
  [199, 312, 57],
  [199, 217, 278],
  [199, 136, 35, 111],
  [199, 107, 1, 152, 1],
  [199, 152, 43, 34, 13],
  [199, 46, 41, 31, 122],
  [199, 215, 121, 4],
  [199, 51, 302],
  [199, 160, 1, 165],
  [199, 200, 1, 75],
  [199, 90, 266],
  [199, 108, 171, 57],
  [199, 17, 88, 278],
  [199, 137, 215],
  [199, 261, 35, 13],
  [199, 62, 29, 69, 58],
  [199, 263, 71],
  [199, 152, 1, 177],
  [199, 297, 146],
  [199, 19, 240, 121],
  [199, 342, 17],
  [199, 71, 61, 221],
  [199, 44, 5, 57, 217],
  [199, 201, 226],
  [199, 108, 35, 221],
  [199, 303, 82],
  [199, 168, 127, 75],
  [199, 89, 34, 221],
  [199, 108, 116, 171],
  [199, 107, 35, 34, 88],
  [199, 113, 48, 113, 48],
  [199, 1, 127, 127, 71],
  [199, 46, 49, 51, 46, 41],
  [199, 34, 1, 188, 88],
  [199, 213, 1, 180],
  [199, 17, 272, 35],
  [199, 108, 127, 127],
  [199, 34, 3, 161, 137],
  [199, 277, 278],
  [199, 107, 247, 17],
  [199, 168, 286],
  [199, 169, 88, 194],
  [199, 61, 61, 61, 34, 97],
  [199, 46, 49, 29, 17, 88],
  [199, 46, 35, 88, 175],
  [199, 135, 92, 36, 36],
  [199, 42, 217, 180],
  [199, 263, 203],
  [199, 137, 34, 199],
  [199, 109, 329],
  [199, 179, 56, 231],
  [199, 107, 1, 218, 35],
  [199, 149, 152, 75],
  [199, 302, 182],
  [199, 46, 41, 31, 14, 151],
  [199, 142, 75, 25, 88],
  [199, 154, 222, 59],
  [199, 132, 312],
  [199, 217, 300],
  [199, 305, 42, 34],
  [199, 113, 323],
  [199, 247, 152, 17],
  [199, 302, 167],
  [199, 294, 249],
  [192, 278, 82, 199],
  [199, 257, 113, 39, 59],
  [199, 165, 57, 300],
  [199, 317, 217],
  [199, 146, 146, 152],
  [199, 336, 129],
  [199, 75, 49, 152, 121],
  [199, 263, 152, 17],
  [199, 17, 152, 17, 171],
  [199, 156, 1, 312],
  [199, 138, 138, 278],
  [199, 109, 122, 122, 122],
  [199, 247, 309],
  [199, 57, 5, 57, 5, 151, 151],
  [199, 257, 1, 88, 108],
  [199, 71, 71, 71, 1, 38],
  [199, 84, 278, 161],
  [199, 72, 88, 108, 97],
  [199, 336, 152],
  [199, 88, 88, 88, 137],
  [199, 222, 71, 108, 17],
  [199, 273, 1, 88, 108],
  [199, 109, 136, 35, 39, 59],
  [199, 4, 29, 317, 75],
  [199, 42, 19, 35, 319],
  [199, 240, 46, 41, 161],
  [199, 222, 43, 274],
  [199, 106, 363],
  [199, 273, 19, 16, 120],
  [199, 107, 185, 221, 25],
  [199, 149, 51, 1, 307],
  [199, 46, 88, 51, 307],
  [199, 329, 266],
  [199, 72, 14, 73, 1, 88, 82],
  [199, 262, 31, 1, 221],
  [199, 28, 51, 1, 35, 34, 215],
  [199, 299, 342],
  [199, 107, 1, 87, 312],
  [199, 312, 147, 147],
  [199, 271, 217, 185],
  [199, 72, 222, 312],
  [199, 107, 286, 43, 180],
  [199, 107, 317, 217],
  [199, 44, 5, 57, 344, 57],
  [199, 152, 136, 35, 199],
  [199, 151, 87, 1, 4, 312],
  [199, 17, 152, 17, 34, 299],
  [199, 46, 13, 88, 278, 118, 105],
  [199, 19, 237, 237, 151],
  [199, 149, 250, 314],
  [199, 305, 57, 312],
  [199, 113, 1, 35, 92, 37, 37, 37, 37],
  [199, 336, 305, 49],
  [199, 150, 88, 88, 342],
  [199, 330, 106, 196, 21, 21],
  [199, 366, 342],
  [199, 319, 300, 300, 113],
  [199, 319, 300, 300, 137],
  [180, 43, 5],
  [180, 42, 34],
  [180, 121, 4],
  [180, 46, 39],
  [180, 46, 127],
  [180, 73, 52],
  [90, 19, 180],
  [180, 43, 111],
  [180, 29, 88],
  [143, 57, 180],
  [180, 136, 35],
  [180, 99, 88],
  [180, 133, 26],
  [46, 88, 65, 180],
  [180, 99, 165],
  [1, 151, 27, 180],
  [180, 152, 67],
  [180, 247, 88],
  [137, 137, 180],
  [180, 42, 286],
  [278, 142, 180],
  [180, 305, 49],
  [180, 240, 129],
  [108, 171, 57, 180],
  [180, 263, 71],
  [180, 152, 247],
  [42, 114, 161, 180],
  [180, 204, 137],
  [180, 42, 30, 34, 75],
  [152, 43, 88, 180],
  [152, 57, 88, 180],
  [180, 1, 4, 312],
  [180, 152, 1, 177],
  [133, 152, 180],
  [180, 169, 299],
  [180, 285, 185],
  [61, 278, 21, 21, 180],
  [180, 107, 35, 34, 88],
  [301, 111, 180],
  [46, 88, 122, 104, 180],
  [109, 203, 82, 180],
  [72, 46, 41, 161, 180],
  [71, 35, 71, 104, 180],
  [72, 88, 108, 88, 180],
  [180, 46, 49, 147, 152],
  [180, 278, 142, 180],
  [42, 286, 180, 180],
  [180, 257, 1, 88, 108],
  [180, 46, 88, 122, 161],
  [180, 75, 35, 152, 121],
  [180, 293, 103, 80],
  [180, 71, 71, 71, 1, 38],
  [135, 88, 86, 21, 180],
  [199, 152, 1, 177, 180],
  [180, 336, 185],
  [180, 180, 51, 88, 4, 88],
  [180, 88, 88, 88, 137],
  [180, 143, 59, 293],
  [180, 49, 307, 182],
  [180, 286, 71, 39, 59],
  [180, 222, 43, 274],
  [180, 319, 180, 59],
  [180, 34, 1, 157, 49, 152],
  [180, 152, 136, 35, 199],
  [180, 127, 240, 127, 137],
  [180, 271, 217, 221],
  [180, 336, 305, 49],
  [286, 30, 1, 18, 51, 137, 137, 75, 180],
  [168, 1, 20, 59],
  [168, 222, 297, 82],
  [170, 103, 103],
  [170, 260, 124],
  [75, 37, 37, 37, 37],
  [1, 35, 92, 37, 37, 37, 37],
  [192, 71, 34, 34, 89, 88],
  [166, 19, 59],
  [166, 205, 137],
  [166, 169, 303],
  [166, 72, 222, 312],
  [164, 17, 88],
  [164, 172, 1],
  [164, 72, 82],
  [109, 3, 181, 164],
  [237, 57, 164],
  [164, 75, 25, 88],
  [164, 46, 88, 29, 88],
  [180, 180, 51, 164],
  [202, 161, 19, 164],
  [164, 72, 222, 312],
  [142, 43, 88],
  [142, 46, 170],
  [142, 107, 82],
  [142, 88, 278],
  [142, 75, 34, 34],
  [142, 5, 297],
  [142, 152, 152],
  [142, 168, 189],
  [142, 122, 221],
  [142, 132, 171],
  [142, 217, 1, 120],
  [142, 152, 235],
  [142, 34, 53, 31, 75],
  [142, 49, 306],
  [49, 306, 142],
  [142, 277, 278],
  [142, 71, 88, 175],
  [142, 319, 142],
  [142, 4, 234, 1, 91],
  [192, 278, 82, 142],
  [142, 247, 152, 17],
  [142, 19, 108, 116, 42, 34],
  [142, 222, 43, 274],
  [142, 107, 185, 221, 25],
  [142, 150, 342, 59],
  [142, 128, 89, 34, 322],
  [142, 106, 135, 1, 217, 35],
  [88, 88, 217, 1, 88, 142],
  [142, 305, 57, 312],
  [271, 1, 88, 29, 108, 3, 142],
  [142, 222, 300, 342],
  [46, 127, 181, 17],
  [129, 16, 38],
  [129, 34, 1, 62],
  [129, 34, 83],
  [129, 28, 88],
  [129, 34, 97],
  [129, 4, 202],
  [129, 47, 129],
  [129, 40, 1, 91],
  [129, 5, 297],
  [129, 84, 128],
  [129, 148, 124],
  [129, 237, 57],
  [129, 271, 38],
  [129, 261, 35],
  [129, 137, 137],
  [129, 129, 34, 1, 14],
  [129, 129, 21, 21],
  [129, 129, 194],
  [129, 199, 71, 61],
  [129, 51, 302],
  [129, 154, 215],
  [129, 252, 52, 59],
  [129, 128, 4, 89, 37],
  [129, 17, 88, 278],
  [129, 149, 224],
  [129, 34, 1, 171, 57],
  [129, 90, 266],
  [129, 129, 248],
  [129, 121, 312],
  [129, 278, 279],
  [180, 180, 51, 129, 5],
  [129, 5, 297, 124],
  [129, 179, 56, 231],
  [129, 247, 152, 17],
  [129, 135, 1, 217, 35],
  [129, 127, 127, 147, 34],
  [129, 168, 51, 185, 100],
  [109, 88, 247, 17, 129, 5],
  [129, 271, 307],
  [129, 222, 1, 88, 175],
  [1, 35, 92, 37, 37, 37, 37, 129, 5],
  [129, 42, 30, 34, 215, 161],
  [75, 1, 26, 241],
  [263, 203, 241],
  [247, 20, 241, 247, 17],
  [108, 77, 212],
  [16, 42, 34, 212],
  [263, 71, 212],
  [23, 88, 88, 88, 212],
  [46, 125, 342, 212],
  [261, 35, 13],
  [67, 88, 204],
  [179, 19, 235],
  [235, 217, 58],
  [62, 29, 69],
  [217, 34, 32],
  [217, 1, 120],
  [217, 49, 69],
  [44, 5, 57, 217],
  [61, 75, 217],
  [46, 127, 217],
  [46, 217, 101],
  [217, 99, 88],
  [217, 71, 71],
  [296, 217, 1],
  [217, 136, 35],
  [121, 51, 88, 217],
  [217, 75, 25, 88],
  [113, 71, 1, 217, 1, 217, 1],
  [217, 72, 14, 73, 1, 88, 82],
  [217, 217, 217, 51, 216],
  [14, 151, 84, 128],
  [21, 31, 75, 62, 14, 151],
  [246, 31, 7],
  [246, 34, 32],
  [246, 39, 59],
  [246, 151, 21],
  [246, 34, 97],
  [246, 99, 50],
  [246, 31, 75],
  [246, 1, 151],
  [246, 36, 129],
  [246, 72, 82],
  [246, 1, 88, 108],
  [246, 1, 128, 73],
  [246, 62, 29, 69],
  [246, 141, 88],
  [246, 237, 57],
  [246, 72, 185],
  [246, 180, 180],
  [246, 138, 138],
  [246, 31, 75, 88],
  [246, 237, 118],
  [246, 46, 41, 34, 50],
  [246, 46, 34, 34, 17],
  [246, 34, 1, 171, 57],
  [246, 40, 1, 274],
  [246, 152, 1, 177],
  [246, 42, 30, 34, 75],
  [246, 89, 34, 221],
  [246, 34, 181, 278],
  [246, 34, 1, 188, 88],
  [246, 44, 5, 57, 217],
  [246, 286, 3, 59],
  [246, 271, 81],
  [246, 149, 34, 1, 88],
  [246, 247, 152, 17],
  [246, 58, 88, 278],
  [246, 75, 49, 152, 121],
  [246, 180, 180, 51, 58],
  [246, 88, 88, 88, 90],
  [246, 34, 1, 171, 57, 185],
  [246, 109, 88, 247, 17],
  [246, 149, 350],
  [246, 294, 354],
  [246, 96, 90, 1, 4, 71, 161],
  [246, 305, 57, 312],
  [246, 42, 217, 180, 294],
  [246, 124, 168, 73, 114, 185],
  [246, 366, 342],
  [246, 17, 272, 35, 1, 4, 312],
  [257, 1, 75],
  [257, 1, 88, 108],
  [257, 113, 39, 59],
  [240, 43, 5],
  [21, 21, 240],
  [240, 19, 59],
  [240, 46, 170],
  [240, 164, 88],
  [240, 107, 16, 38],
  [240, 90, 1, 88, 108],
  [255, 43, 111],
  [61, 35, 100, 255],
  [43, 116, 43, 116, 255],
  [35, 57, 221],
  [60, 59, 221],
  [108, 35, 221],
  [71, 61, 221],
  [34, 1, 88, 221],
  [20, 180, 221],
  [213, 9, 221],
  [199, 42, 34, 221],
  [147, 147, 221],
  [152, 171, 221],
  [187, 1, 180, 221],
  [262, 31, 1, 221],
  [287, 39, 59, 221],
  [286, 199, 221],
  [271, 217, 221],
  [199, 152, 1, 177, 221],
  [46, 13, 215],
  [215, 35, 57],
  [33, 17, 215],
  [121, 4, 215],
  [17, 272, 35],
  [215, 4, 183],
  [215, 46, 127],
  [215, 22, 129],
  [49, 131, 215],
  [215, 61, 164],
  [215, 38, 181],
  [222, 34, 34, 34],
  [215, 89, 137],
  [215, 132, 171],
  [215, 168, 189],
  [215, 137, 34, 34],
  [28, 1, 121, 59, 215],
  [215, 71, 35, 71],
  [215, 286, 38],
  [222, 71, 108, 17],
  [215, 168, 137],
  [215, 133, 152],
  [215, 257, 1, 75],
  [28, 51, 1, 35, 34, 215],
  [215, 149, 217],
  [215, 293, 182],
  [215, 71, 61, 221],
  [215, 107, 132, 88],
  [215, 51, 152, 46, 35],
  [215, 135, 92, 36, 36],
  [215, 263, 203],
  [215, 121, 51, 88, 71],
  [321, 161, 215],
  [215, 342, 180],
  [215, 257, 1, 88, 108],
  [215, 168, 51, 103, 80],
  [215, 75, 49, 152, 121],
  [215, 324, 161],
  [215, 247, 309],
  [215, 42, 19, 35, 319],
  [72, 88, 108, 17, 59, 215],
  [215, 215, 342],
  [215, 149, 51, 1, 307],
  [17, 272, 17, 272, 17, 272],
  [215, 109, 3, 181, 222, 43, 274],
  [258, 34, 1, 14],
  [61, 31, 75],
  [31, 75, 88],
  [31, 75, 73, 52],
  [31, 75, 1, 88, 108],
  [31, 75, 237, 118],
  [31, 75, 4, 75, 88, 29, 88],
  [209, 121, 4],
  [209, 24, 57],
  [209, 42, 34],
  [209, 1, 77],
  [209, 71, 61],
  [209, 172, 1],
  [209, 28, 88],
  [209, 4, 183],
  [209, 67, 49],
  [209, 22, 91],
  [209, 4, 202],
  [209, 121, 278],
  [209, 84, 128],
  [209, 171, 171],
  [209, 29, 71, 88],
  [209, 123, 199],
  [209, 46, 34, 34, 17],
  [209, 107, 103, 54],
  [209, 205, 137],
  [129, 240, 209],
  [209, 1, 4, 312],
  [209, 108, 229],
  [209, 90, 19, 180],
  [209, 109, 136, 120],
  [209, 108, 127, 127],
  [209, 46, 49, 51, 46, 41],
  [209, 209, 209],
  [209, 294, 4, 73],
  [287, 39, 59, 209],
  [209, 71, 61, 221],
  [209, 326, 82],
  [106, 138, 138, 209],
  [72, 195, 39, 59, 209],
  [209, 127, 127, 147, 34],
  [209, 257, 1, 88, 108],
  [209, 293, 103, 80],
  [209, 135, 1, 217, 35],
  [209, 342, 180],
  [209, 137, 137, 14, 151],
  [209, 21, 31, 75, 62, 14, 151],
  [209, 106, 135, 1, 217, 35],
  [209, 19, 149, 350],
  [209, 240, 127, 127, 137],
  [209, 137, 37, 37, 137, 75, 209],
  [203, 1, 75],
  [203, 20, 88],
  [203, 172, 1],
  [203, 88, 38],
  [116, 56, 203],
  [203, 9, 1, 88],
  [278, 59, 203],
  [203, 261, 35],
  [203, 123, 199],
  [137, 137, 203],
  [203, 277, 88],
  [203, 28, 312],
  [203, 1, 88, 217],
  [96, 283, 54, 203],
  [203, 72, 88, 108, 88],
  [203, 273, 1, 88, 108],
  [203, 72, 14, 73, 1, 88, 82],
  [34, 179, 56, 231],
  [237, 50, 17],
  [237, 121, 4],
  [237, 67, 49],
  [237, 103, 103],
  [237, 37, 20, 91],
  [237, 35, 88, 38],
  [237, 152, 67],
  [237, 88, 4, 71],
  [237, 121, 278],
  [237, 34, 1, 14, 185],
  [237, 71, 35, 100],
  [46, 89, 88, 237],
  [237, 29, 71, 88],
  [237, 168, 226],
  [237, 217, 35, 100],
  [237, 168, 286],
  [237, 107, 307],
  [237, 76, 5, 21, 152],
  [237, 46, 88, 29, 88],
  [72, 51, 1, 237, 39, 59],
  [237, 240, 121, 97],
  [42, 217, 180, 237],
  [237, 132, 312],
  [21, 237, 1, 4, 312],
  [237, 326, 185],
  [237, 71, 34, 34, 89, 88],
  [237, 151, 213, 1, 87],
  [237, 168, 73, 114, 185],
  [237, 150, 342, 59],
  [107, 35, 63],
  [107, 35, 113],
  [107, 35, 73],
  [107, 35, 148],
  [107, 35, 142],
  [107, 35, 236],
  [107, 35, 127, 58],
  [107, 35, 268],
  [107, 35, 38, 181],
  [107, 35, 164, 88],
  [107, 35, 4, 89, 98],
  [107, 35, 197, 88],
  [107, 35, 109, 116, 56],
  [107, 35, 333],
  [107, 35, 277, 88],
  [107, 35, 199, 71, 71],
  [107, 35, 315, 113],
  [107, 35, 294, 180],
  [107, 35, 131, 311],
  [107, 35, 286, 322],
  [107, 35, 240, 46, 41, 161],
  [107, 35, 72, 222, 312],
  [107, 35, 317, 28, 30, 34, 29, 61],
  [247, 28, 88],
  [247, 152, 38],
  [247, 152, 17],
  [247, 61, 35, 100],
  [247, 152, 43, 34, 13],
  [247, 90, 266],
  [247, 88, 38, 247, 88, 38],
  [284, 4, 75],
  [284, 61, 88],
  [284, 34, 82],
  [284, 191, 4],
  [284, 72, 82],
  [284, 278, 58],
  [284, 43, 152],
  [284, 34, 147],
  [284, 22, 129],
  [284, 29, 1, 88],
  [284, 34, 1, 88],
  [284, 71, 43, 16],
  [284, 4, 151, 119],
  [284, 185, 190],
  [284, 147, 147],
  [284, 168, 189],
  [284, 199, 240],
  [284, 261, 35],
  [284, 215, 77],
  [284, 160, 204],
  [284, 107, 234],
  [284, 108, 171, 57],
  [284, 137, 215],
  [284, 213, 1, 88],
  [284, 297, 54],
  [284, 240, 129],
  [284, 302, 54],
  [284, 123, 290],
  [284, 169, 228],
  [284, 73, 105, 137],
  [284, 46, 88, 29, 88],
  [284, 113, 48, 113, 48],
  [284, 215, 75, 61],
  [284, 4, 234, 1, 91],
  [284, 99, 165, 182],
  [284, 222, 213, 5],
  [284, 46, 41, 31, 31, 75],
  [284, 46, 155, 175],
  [284, 336, 152],
  [284, 156, 1, 312],
  [284, 135, 1, 217, 35],
  [284, 146, 146, 152],
  [284, 250, 314],
  [284, 42, 19, 35, 319],
  [284, 261, 35, 255],
  [284, 34, 1, 88, 34, 88, 34],
  [284, 106, 363],
  [284, 199, 303, 82],
  [284, 262, 31, 1, 221],
  [284, 72, 14, 73, 1, 88, 82],
  [284, 280, 136, 152],
  [284, 171, 49, 131, 199],
  [284, 305, 57, 312],
  [284, 34, 34, 147, 347],
  [284, 179, 56, 231, 342],
  [284, 222, 300, 342],
  [284, 34, 88, 88, 88, 228, 1, 4, 312],
  [293, 35, 57],
  [113, 293, 113],
  [199, 57, 5, 5, 293],
  [4, 89, 317, 26],
  [293, 46, 34, 34, 17],
  [293, 107, 203],
  [293, 132, 171],
  [293, 17, 88, 278],
  [293, 294, 180],
  [293, 106, 187, 88],
  [293, 164, 88, 182],
  [106, 138, 138, 293],
  [293, 217, 136, 35],
  [293, 106, 345],
  [293, 149, 152, 75],
  [293, 152, 1, 309],
  [293, 329, 266],
  [300, 43, 5],
  [300, 31, 7],
  [300, 57, 5],
  [300, 21, 21],
  [300, 121, 4],
  [300, 35, 57],
  [300, 16, 61],
  [300, 46, 41],
  [17, 51, 300],
  [151, 21, 300],
  [137, 7, 300],
  [300, 57, 88],
  [300, 116, 56],
  [300, 99, 50],
  [300, 46, 127],
  [300, 49, 69],
  [300, 72, 88],
  [300, 57, 194],
  [300, 46, 170],
  [300, 99, 88],
  [300, 43, 152],
  [300, 34, 1, 88],
  [300, 147, 20],
  [118, 88, 300],
  [300, 84, 38],
  [300, 88, 278],
  [300, 165, 124],
  [300, 168, 118],
  [300, 89, 137],
  [300, 1, 128, 73],
  [300, 107, 203],
  [300, 123, 199],
  [300, 29, 71, 88],
  [300, 107, 234],
  [300, 29, 108, 90],
  [300, 29, 108, 46, 13],
  [300, 59, 59, 59, 59],
  [300, 168, 137],
  [300, 34, 1, 228],
  [300, 75, 25, 88],
  [300, 107, 1, 151],
  [300, 28, 152, 17],
  [300, 71, 35, 100],
  [300, 240, 91],
  [300, 128, 217],
  [262, 59, 300],
  [300, 133, 152],
  [300, 213, 1, 88],
  [300, 240, 199],
  [300, 88, 260],
  [300, 238, 39, 59],
  [300, 46, 49, 51, 91],
  [300, 123, 290],
  [300, 169, 228],
  [300, 168, 1, 20, 59],
  [300, 115, 88, 80],
  [300, 305, 49],
  [300, 268, 161],
  [180, 180, 51, 300],
  [300, 108, 35, 221],
  [300, 303, 82],
  [272, 121, 4, 300],
  [300, 99, 165, 182],
  [300, 107, 34, 1, 240],
  [300, 96, 34, 34, 151],
  [300, 217, 300],
  [300, 4, 89, 98, 185],
  [300, 132, 312],
  [31, 198, 161, 300],
  [300, 90, 171, 171],
  [300, 128, 333],
  [300, 299, 34, 97],
  [300, 46, 49, 147, 152],
  [300, 294, 108, 88],
  [300, 75, 49, 152, 121],
  [300, 71, 71, 71, 1, 38],
  [300, 250, 314],
  [300, 34, 1, 157, 49, 152],
  [302, 56, 39, 59, 300],
  [150, 29, 3, 300, 274],
  [300, 222, 71, 108, 17],
  [300, 127, 127, 1, 127, 127, 12],
  [300, 107, 1, 87, 312],
  [284, 215, 75, 300],
  [300, 72, 222, 312],
  [300, 106, 309, 35, 71],
  [300, 312, 312, 118],
  [300, 34, 34, 147, 347],
  [300, 262, 31, 1, 222, 311],
  [72, 195, 39, 59, 279],
  [279, 263, 152, 17],
  [279, 150, 88, 88, 342],
  [29, 108, 46, 13],
  [51, 35, 67],
  [222, 17, 88],
  [222, 28, 152, 17],
  [222, 17, 272],
  [222, 319, 57],
  [222, 133, 152],
  [222, 61, 278, 21, 21],
  [222, 185, 342],
  [222, 300, 342],
  [222, 348, 359],
  [150, 3, 67],
  [294, 34, 1, 62],
  [294, 4, 193],
  [197, 88, 294],
  [294, 199, 42, 34],
  [294, 152, 43, 34, 13],
  [294, 46, 89, 88, 152, 1],
  [46, 13, 88, 278, 294, 105],
  [294, 180, 294, 75],
  [35, 61, 299],
  [17, 59, 299],
  [34, 1, 62, 299],
  [34, 1, 88, 299],
  [299, 46, 34, 34, 17],
  [169, 228, 299],
  [17, 152, 17, 34, 299],
  [240, 75, 17, 299],
  [22, 31, 75, 39, 59, 299],
  [71, 71, 71, 1, 38, 299],
  [317, 217, 299],
  [299, 217, 136, 35],
  [121, 1, 38, 299, 342],
  [133, 21, 152],
  [280, 16, 61],
  [260, 34, 1, 62],
  [260, 102, 54],
  [260, 218, 26],
  [260, 59, 34, 34, 34],
  [260, 127, 127, 256],
  [260, 4, 89, 98, 185],
  [72, 46, 41, 161, 260],
  [72, 195, 39, 59, 260],
  [96, 34, 34, 151, 260],
  [260, 260, 260],
  [260, 46, 49, 147, 152],
  [260, 4, 71, 20, 222, 1, 185],
  [21, 31, 75, 296],
  [169, 161, 296],
  [46, 13, 278],
  [278, 39, 59],
  [278, 17, 59],
  [278, 46, 41],
  [278, 20, 61],
  [37, 20, 278],
  [86, 21, 278],
  [278, 61, 88],
  [278, 49, 69],
  [278, 141, 1],
  [278, 43, 111],
  [17, 88, 278],
  [4, 127, 278, 7],
  [14, 151, 278],
  [278, 89, 75],
  [278, 121, 1, 38],
  [278, 99, 88],
  [278, 29, 1, 88],
  [278, 43, 37, 56],
  [61, 278, 21, 21],
  [278, 21, 152],
  [58, 58, 58, 278],
  [278, 42, 19, 54],
  [278, 33, 176],
  [34, 181, 278],
  [278, 17, 51, 122],
  [19, 16, 120, 278],
  [34, 3, 161, 278],
  [278, 35, 88, 38],
  [278, 46, 34, 99, 5],
  [262, 59, 278],
  [106, 34, 82, 278],
  [278, 106, 34, 82],
  [278, 107, 35, 73],
  [278, 107, 103, 54],
  [278, 152, 235],
  [278, 128, 4, 89, 37],
  [278, 217, 185],
  [278, 263, 118],
  [278, 296, 119],
  [278, 35, 34, 88, 54],
  [278, 152, 1, 177],
  [278, 31, 152, 100],
  [278, 240, 199],
  [278, 297, 182],
  [278, 46, 49, 51, 46, 41],
  [278, 1, 88, 29, 108, 3],
  [278, 303, 82],
  [46, 88, 51, 88, 278],
  [271, 217, 278],
  [278, 121, 51, 88, 71],
  [278, 149, 152, 75],
  [278, 137, 34, 199],
  [278, 299, 34, 97],
  [278, 72, 88, 108, 97],
  [278, 83, 16, 312],
  [278, 294, 108, 88],
  [106, 34, 342, 278],
  [278, 42, 19, 35, 319],
  [278, 34, 1, 157, 49, 152],
  [278, 273, 19, 16, 120],
  [109, 136, 35, 39, 59, 278],
  [109, 88, 247, 17, 278],
  [278, 46, 125, 342],
  [278, 247, 152, 185],
  [278, 34, 1, 88, 34, 88, 34],
  [278, 107, 1, 87, 312],
  [278, 128, 89, 34, 322],
  [278, 271, 217, 221],
  [278, 149, 192, 147, 262],
  [192, 147, 262],
  [262, 31, 88, 88, 88],
  [72, 88, 51, 268],
  [268, 200, 237],
  [286, 217, 58],
  [286, 27, 4, 1, 35],
  [286, 30, 1, 88, 1, 35],
  [286, 27, 4, 1, 35, 69],
  [150, 342, 286],
  [34, 67, 88],
  [67, 88, 172],
  [34, 67, 88, 62, 14, 8],
  [67, 88, 1, 75, 185],
  [168, 51, 103, 80],
  [339, 103, 80],
  [287, 19, 59],
  [287, 46, 39],
  [287, 46, 41],
  [287, 39, 59],
  [287, 107, 21],
  [287, 46, 127],
  [287, 35, 88],
  [287, 4, 71, 119],
  [287, 286, 3, 59],
  [287, 34, 1, 188, 88],
  [287, 262, 31, 1, 221],
  [149, 43, 5],
  [149, 46, 13],
  [149, 39, 59],
  [149, 34, 32],
  [149, 35, 57],
  [149, 34, 21],
  [149, 46, 41],
  [43, 116, 43, 116],
  [149, 103, 54],
  [149, 34, 1, 62],
  [149, 61, 88],
  [149, 25, 88],
  [149, 43, 111],
  [149, 43, 88],
  [149, 73, 52],
  [149, 20, 88],
  [149, 17, 88],
  [149, 107, 25],
  [149, 116, 56],
  [149, 199, 54],
  [149, 58, 88],
  [149, 103, 88],
  [149, 199, 46, 13],
  [149, 4, 202],
  [149, 127, 127],
  [149, 89, 88],
  [149, 89, 75],
  [149, 34, 137],
  [149, 118, 88],
  [149, 43, 152],
  [149, 152, 17],
  [149, 1, 77, 57],
  [149, 20, 3, 122],
  [149, 46, 13, 128],
  [149, 58, 58, 58],
  [149, 34, 25, 88],
  [149, 17, 51, 122],
  [149, 142, 180],
  [149, 34, 1, 137],
  [149, 237, 57],
  [149, 192, 72],
  [149, 199, 121, 4],
  [149, 88, 4, 88],
  [149, 31, 198],
  [149, 1, 128, 73],
  [149, 247, 17],
  [149, 107, 16, 38],
  [149, 237, 60],
  [149, 75, 34, 34],
  [149, 152, 75],
  [149, 75, 5, 77],
  [149, 132, 171],
  [149, 107, 234],
  [149, 43, 293],
  [149, 89, 237],
  [149, 168, 137],
  [149, 199, 255],
  [149, 247, 88],
  [149, 1, 149, 69],
  [149, 122, 241],
  [149, 71, 35, 100],
  [149, 75, 230],
  [149, 46, 41, 34, 50],
  [149, 28, 1, 121, 59],
  [149, 46, 34, 34, 17],
  [149, 43, 279],
  [149, 137, 34, 34],
  [149, 152, 171],
  [149, 199, 67, 49],
  [149, 237, 118],
  [149, 107, 1, 152, 1],
  [149, 277, 88],
  [149, 88, 88, 1, 26],
  [149, 199, 99, 88],
  [149, 205, 137],
  [149, 133, 152],
  [149, 152, 43, 34, 13],
  [149, 43, 303],
  [149, 115, 88, 80],
  [149, 240, 194],
  [149, 17, 88, 278],
  [149, 143, 21, 77],
  [149, 257, 1, 75],
  [149, 51, 302],
  [149, 46, 49, 51, 91],
  [149, 51, 1, 307],
  [149, 203, 203],
  [149, 199, 34, 247],
  [149, 199, 303],
  [149, 14, 199, 1, 180],
  [149, 34, 1, 188, 88],
  [149, 46, 88, 29, 88],
  [149, 122, 4, 300],
  [149, 46, 127, 217],
  [149, 106, 135, 91],
  [149, 107, 35, 34, 88],
  [149, 71, 61, 221],
  [149, 34, 247, 88],
  [149, 99, 165, 182],
  [149, 302, 182],
  [149, 107, 34, 1, 240],
  [150, 222, 213, 5],
  [149, 154, 222, 59],
  [149, 43, 1, 88, 217],
  [149, 106, 135, 180],
  [149, 109, 203, 82],
  [149, 278, 59, 203],
  [149, 192, 278, 82],
  [149, 4, 89, 98, 185],
  [149, 14, 151, 84, 128],
  [149, 124, 34, 1, 14, 16, 61],
  [149, 321, 161],
  [149, 317, 217],
  [149, 263, 152, 17],
  [149, 342, 180],
  [149, 185, 185, 185],
  [149, 326, 185],
  [149, 19, 108, 116, 42, 34],
  [149, 199, 152, 1, 177],
  [149, 339, 180],
  [149, 250, 314],
  [149, 329, 217],
  [149, 199, 303, 82],
  [149, 96, 90, 1, 38, 161],
  [149, 1, 217, 1, 217, 1],
  [149, 71, 34, 34, 89, 88],
  [149, 4, 234, 247, 17],
  [149, 109, 88, 247, 17],
  [149, 124, 179, 19, 235],
  [149, 247, 137, 167],
  [149, 222, 133, 152],
  [149, 280, 136, 152],
  [149, 262, 31, 1, 221],
  [149, 192, 147, 262],
  [149, 318, 240, 38],
  [149, 280, 350],
  [149, 71, 35, 71, 104, 16, 61],
  [149, 171, 49, 131, 199],
  [149, 240, 127, 127, 137],
  [149, 199, 317, 217],
  [149, 371, 161],
  [149, 319, 152, 43, 34, 13],
  [149, 336, 342],
  [149, 199, 88, 88, 88, 137],
  [149, 271, 217, 221],
  [149, 42, 217, 180, 237],
  [149, 300, 89, 34, 221],
  [149, 151, 87, 1, 4, 312],
  [149, 106, 138, 138, 209],
  [149, 109, 88, 247, 17, 137],
  [149, 42, 217, 180, 294],
  [149, 336, 305, 49],
  [149, 306, 342, 180],
  [149, 222, 300, 342],
  [271, 99, 39],
  [271, 155, 58],
  [271, 88, 10, 75],
  [88, 26, 271, 38],
  [271, 342, 1, 26],
  [11, 1, 274],
  [274, 113, 3],
  [274, 35, 61],
  [59, 5, 5, 274],
  [116, 1, 274],
  [274, 50, 17],
  [274, 25, 88],
  [274, 46, 71],
  [274, 17, 88],
  [274, 107, 21],
  [14, 151, 274],
  [274, 89, 88],
  [274, 71, 71],
  [274, 4, 202],
  [274, 46, 170],
  [274, 34, 1, 88],
  [274, 62, 29, 69],
  [274, 35, 88, 38],
  [222, 43, 274],
  [274, 99, 165],
  [19, 16, 120, 274],
  [160, 167, 274],
  [274, 88, 10, 75],
  [274, 4, 71, 119],
  [274, 31, 75, 88],
  [107, 248, 274],
  [274, 122, 221],
  [274, 137, 167],
  [274, 152, 177],
  [274, 132, 171],
  [274, 107, 103, 54],
  [274, 237, 50, 17],
  [274, 240, 129],
  [274, 169, 228],
  [274, 1, 88, 217],
  [274, 252, 52, 59],
  [11, 17, 274, 274],
  [274, 17, 88, 278],
  [274, 205, 137],
  [274, 277, 88],
  [274, 5, 297, 124],
  [274, 46, 49, 51, 46, 41],
  [1, 88, 29, 108, 3, 274],
  [274, 51, 152, 46, 35],
  [180, 180, 51, 274],
  [71, 174, 161, 274],
  [274, 121, 51, 88, 41],
  [274, 217, 300],
  [274, 46, 127, 181, 17],
  [274, 149, 152, 75],
  [274, 89, 147, 88, 2],
  [274, 317, 185],
  [274, 247, 152, 17],
  [274, 149, 142, 77],
  [274, 71, 71, 71, 1, 38],
  [274, 274, 274],
  [42, 29, 71, 57, 164, 274],
  [274, 294, 313],
  [274, 152, 43, 34, 13, 42, 34],
  [274, 46, 88, 51, 307],
  [274, 329, 266],
  [274, 128, 89, 34, 322],
  [123, 290, 274, 274],
  [200, 152, 274, 274],
  [274, 19, 149, 350],
  [274, 274, 274, 221],
  [146, 146, 152, 274, 274],
  [207, 51, 209, 274, 274],
  [319, 300, 300, 274],
  [96, 138, 5, 16, 8],
  [46, 155, 175],
  [46, 156, 175],
  [71, 88, 175],
  [58, 88, 291],
  [34, 83, 291],
  [291, 43, 111],
  [291, 152, 1],
  [46, 35, 88, 175],
  [291, 34, 142],
  [17, 147, 291],
  [143, 57, 291],
  [291, 29, 35, 88],
  [291, 35, 34, 88],
  [291, 197, 88],
  [192, 72, 291],
  [199, 121, 4, 291],
  [121, 51, 88, 291],
  [46, 333, 175],
  [282, 57, 291],
  [291, 71, 71, 28],
  [291, 31, 152, 100],
  [291, 152, 43, 34, 13],
  [46, 34, 88, 137, 175],
  [291, 34, 88, 137],
  [291, 133, 152],
  [291, 19, 16, 120, 82],
  [291, 297, 182],
  [291, 33, 271, 38],
  [291, 106, 302],
  [291, 299, 240],
  [46, 71, 35, 71, 104, 175],
  [46, 88, 88, 201, 175],
  [291, 4, 29, 317, 75],
  [291, 137, 137, 203],
  [291, 121, 51, 88, 217],
  [291, 262, 31, 1, 221],
  [291, 150, 222, 213, 5],
  [291, 247, 137, 311],
  [263, 152, 17],
  [263, 96, 31, 152, 100],
  [34, 1, 171, 57, 311],
  [247, 137, 311],
  [90, 1, 88, 108, 311],
  [286, 37, 37, 51, 311],
  [262, 31, 1, 222, 311],
  [150, 88, 88, 342, 311],
  [42, 29, 71],
  [42, 29, 71, 57, 164],
  [42, 29, 71, 31, 152, 1, 177],
  [42, 29, 71, 222, 43, 274],
  [319, 11, 17],
  [319, 31, 7],
  [319, 34, 21],
  [319, 35, 61],
  [319, 46, 41],
  [319, 39, 59],
  [319, 167, 5],
  [319, 34, 97],
  [319, 46, 71],
  [319, 1, 151],
  [319, 17, 88],
  [319, 172, 1],
  [319, 57, 88],
  [319, 67, 49],
  [319, 9, 1, 88],
  [319, 43, 152],
  [319, 21, 152],
  [319, 83, 73],
  [319, 72, 82],
  [319, 107, 94],
  [319, 33, 1, 88],
  [319, 42, 19, 54],
  [319, 36, 129],
  [319, 72, 88],
  [319, 95, 88],
  [42, 19, 35, 319],
  [319, 20, 180],
  [319, 75, 1, 26],
  [319, 72, 185],
  [319, 57, 5, 185],
  [319, 142, 129],
  [160, 167, 319],
  [319, 4, 151, 119],
  [319, 237, 60],
  [319, 144, 88],
  [319, 213, 9],
  [319, 147, 77],
  [319, 73, 34, 34],
  [319, 88, 10, 75],
  [319, 164, 88],
  [319, 62, 29, 69],
  [319, 31, 198],
  [319, 35, 88, 38],
  [319, 107, 216],
  [319, 29, 71, 88],
  [319, 42, 286],
  [319, 46, 88, 122],
  [319, 180, 180],
  [319, 237, 118],
  [319, 132, 171],
  [319, 168, 189],
  [319, 46, 88, 121],
  [319, 34, 1, 228],
  [319, 205, 137],
  [319, 46, 49, 51, 91],
  [319, 21, 21, 240],
  [319, 305, 49],
  [319, 34, 1, 171, 57],
  [319, 16, 42, 34, 88],
  [319, 115, 88, 80],
  [319, 40, 1, 274],
  [319, 133, 152],
  [319, 179, 19, 97],
  [319, 149, 20, 88],
  [319, 204, 137],
  [319, 152, 43, 34, 13],
  [319, 217, 278],
  [171, 49, 131, 319],
  [319, 149, 46, 13, 128],
  [319, 293, 182],
  [319, 46, 49, 51, 46, 41],
  [319, 201, 226],
  [319, 315, 82],
  [319, 278, 279],
  [319, 149, 152, 75],
  [319, 299, 34, 97],
  [319, 149, 1, 149, 69],
  [319, 257, 1, 88, 108],
  [319, 258, 29, 35, 88],
  [319, 127, 127, 147, 34],
  [319, 46, 49, 147, 152],
  [319, 263, 152, 17],
  [319, 108, 229, 152],
  [150, 43, 88, 161, 319],
  [109, 88, 247, 17, 319],
  [319, 222, 71, 108, 17],
  [319, 294, 313],
  [319, 156, 1, 234, 182],
  [319, 150, 342, 59],
  [286, 27, 4, 1, 35, 319],
  [319, 72, 222, 312],
  [319, 300, 300, 161],
  [319, 42, 88, 21, 21, 42, 225, 5],
  [319, 46, 88, 88, 201, 175],
  [319, 34, 34, 147, 347],
  [319, 164, 38, 164, 38, 312],
  [35, 34, 88],
  [168, 127, 75, 35, 34, 88],
  [107, 132, 88, 35, 34, 88],
  [1, 88, 108],
  [90, 1, 88, 108],
  [1, 88, 108, 17, 59],
  [1, 88, 108, 107, 103, 54],
  [262, 59, 1, 88, 108],
  [90, 165, 165, 1, 88, 108],
  [90, 165, 165, 1, 88, 108, 71, 61, 221],
  [42, 157, 290],
  [49, 131, 307],
  [46, 88, 51, 307],
  [62, 14, 8, 42, 157, 290],
  [307, 133, 152],
  [318, 43, 5],
  [318, 57, 88],
  [318, 99, 88],
  [318, 1, 240],
  [318, 240, 38],
  [318, 149, 217],
  [35, 57, 312],
  [34, 21, 312],
  [312, 19, 59],
  [34, 1, 14, 312],
  [312, 107, 25],
  [151, 21, 312],
  [83, 16, 312],
  [156, 1, 312],
  [312, 4, 183],
  [34, 83, 312],
  [312, 28, 88],
  [312, 61, 88],
  [44, 5, 57, 312],
  [58, 88, 312],
  [17, 74, 312],
  [312, 99, 88],
  [312, 20, 171],
  [16, 42, 34, 312],
  [312, 147, 20],
  [312, 19, 16, 120],
  [312, 34, 203],
  [107, 1, 87, 312],
  [312, 152, 177],
  [121, 51, 88, 312],
  [312, 247, 88],
  [262, 59, 312],
  [72, 222, 312],
  [312, 147, 147],
  [312, 1, 83, 151],
  [167, 167, 312],
  [312, 133, 152],
  [305, 57, 312],
  [312, 201, 226],
  [107, 201, 35, 312],
  [72, 46, 41, 161, 312],
  [215, 35, 49, 157, 49, 152],
  [164, 38, 164, 38, 312],
  [312, 42, 19, 35, 319],
  [46, 13, 88, 171, 312, 105],
  [312, 192, 147, 262],
  [312, 72, 222, 312],
  [19, 34, 316, 180, 312],
  [247, 152, 17, 99, 73, 312],
  [71, 174, 161],
  [71, 174, 54, 59],
  [71, 174, 71, 174],
  [71, 174, 133, 152],
  [71, 151, 55],
  [71, 151, 28],
  [71, 151, 111],
  [71, 151, 34, 97],
  [71, 151, 57, 88],
  [71, 151, 213],
  [71, 151, 152, 67],
  [71, 151, 121, 278],
  [71, 151, 121, 29, 88],
  [71, 151, 260, 59],
  [71, 151, 43, 116, 43, 116],
  [88, 151, 35],
  [88, 151, 151],
  [88, 151, 61, 88],
  [88, 151, 20, 37, 5],
  [88, 151, 107, 21],
  [88, 151, 43, 111],
  [88, 151, 227],
  [88, 151, 255],
  [88, 151, 73, 52],
  [88, 151, 297],
  [88, 151, 46, 174],
  [88, 151, 75, 1, 26],
  [88, 151, 42, 19, 54],
  [88, 151, 99, 88],
  [88, 151, 38, 181],
  [88, 151, 39, 137],
  [88, 151, 109, 9, 88],
  [88, 151, 199, 152],
  [88, 151, 147, 147],
  [88, 151, 333],
  [88, 151, 31, 75, 88],
  [88, 151, 152, 177],
  [88, 151, 168, 137],
  [88, 151, 108, 229],
  [88, 151, 106, 135, 59],
  [88, 151, 62, 29, 69, 58],
  [88, 151, 352],
  [88, 151, 90, 266],
  [88, 151, 258, 137],
  [88, 151, 46, 49, 51, 91],
  [88, 151, 168, 286],
  [88, 151, 294, 4, 73],
  [88, 151, 168, 127, 75],
  [88, 151, 154, 299],
  [213, 28, 1, 121, 88, 151],
  [88, 151, 135, 92, 36, 36],
  [88, 151, 96, 34, 34, 151],
  [88, 151, 90, 171, 171],
  [88, 151, 257, 1, 88, 108],
  [88, 151, 49, 306, 82],
  [88, 151, 19, 108, 116, 42, 34],
  [88, 151, 71, 71, 71, 1, 38],
  [88, 151, 229, 108, 131],
  [88, 151, 88, 88, 88, 137],
  [88, 151, 71, 294, 182],
  [88, 151, 222, 43, 274],
  [88, 151, 149, 133, 152],
  [149, 350, 88, 151],
  [88, 151, 72, 14, 73, 1, 88, 82],
  [88, 151, 299, 342],
  [88, 151, 49, 306, 75, 124],
  [88, 151, 260, 260, 260],
  [88, 151, 149, 336, 342],
  [315, 39, 137],
  [315, 121, 29, 88],
  [315, 23, 88, 88, 88],
  [302, 57, 5],
  [302, 19, 54],
  [302, 42, 34],
  [302, 25, 88],
  [302, 83, 73],
  [302, 46, 170],
  [17, 147, 302],
  [302, 260, 7],
  [302, 42, 225],
  [302, 1, 128, 73],
  [302, 1, 92, 36, 36],
  [302, 128, 217],
  [121, 1, 38, 51, 302],
  [302, 59, 59, 59, 59],
  [131, 131, 302],
  [302, 34, 1, 228],
  [302, 88, 260],
  [302, 34, 1, 171, 57],
  [302, 1, 88, 217],
  [302, 109, 136, 120],
  [286, 302, 1, 35],
  [72, 51, 1, 302, 39, 59],
  [302, 107, 132, 88],
  [302, 71, 88, 175],
  [302, 326, 82],
  [302, 84, 278, 161],
  [302, 4, 75, 88, 29, 88],
  [302, 293, 103, 80],
  [302, 302, 302],
  [302, 300, 300, 88],
  [17, 88, 247, 17],
  [109, 88, 247, 17],
  [247, 20, 305],
  [247, 20, 58, 247, 17],
  [247, 20, 47, 247, 17],
  [168, 62, 29, 61, 59, 247, 17],
  [247, 20, 300, 247, 17],
  [247, 20, 319, 247, 17],
  [19, 16, 120],
  [19, 16, 120, 82],
  [273, 19, 16, 120],
  [182, 31, 7],
  [182, 11, 17],
  [182, 44, 54],
  [182, 19, 59],
  [57, 88, 182],
  [29, 88, 182],
  [58, 88, 182],
  [4, 131, 182],
  [138, 5, 182],
  [89, 88, 182],
  [4, 234, 182],
  [108, 75, 182],
  [38, 181, 182],
  [33, 1, 88, 182],
  [108, 116, 182],
  [121, 278, 182],
  [237, 60, 182],
  [34, 1, 137, 182],
  [1, 128, 73, 182],
  [142, 180, 182],
  [1, 88, 108, 182],
  [62, 29, 69, 182],
  [142, 129, 182],
  [160, 167, 182],
  [88, 4, 71, 182],
  [164, 88, 182],
  [99, 165, 182],
  [29, 71, 88, 182],
  [71, 35, 71, 182],
  [42, 225, 5, 182],
  [1, 88, 217, 182],
  [34, 1, 171, 57, 182],
  [33, 17, 215, 182],
  [49, 307, 182],
  [46, 41, 31, 122, 182],
  [51, 302, 182],
  [169, 228, 182],
  [277, 88, 182],
  [152, 43, 34, 13, 182],
  [252, 52, 59, 182],
  [240, 129, 182],
  [108, 285, 182],
  [71, 294, 182],
  [115, 88, 80, 182],
  [201, 226, 182],
  [278, 279, 182],
  [122, 4, 300, 182],
  [33, 271, 38, 182],
  [71, 88, 175, 182],
  [156, 1, 234, 182],
  [72, 46, 41, 161, 182],
  [46, 49, 29, 17, 88, 182],
  [106, 135, 180, 182],
  [109, 3, 181, 164, 182],
  [293, 103, 80, 182],
  [49, 306, 82, 182],
  [263, 75, 54, 182],
  [111, 111, 136, 35, 182],
  [156, 1, 312, 182],
  [75, 49, 152, 121, 182],
  [271, 307, 182],
  [109, 88, 247, 17, 182],
  [240, 46, 41, 161, 182],
  [149, 350, 182],
  [42, 29, 71, 57, 164, 182],
  [222, 1, 88, 175, 182],
  [1, 35, 92, 37, 37, 37, 37, 182],
  [285, 107, 35, 46, 41, 182],
  [222, 300, 342, 182],
  [330, 106, 196, 21, 21, 182],
  [128, 88, 194],
  [1, 77, 124],
  [9, 70, 124],
  [57, 88, 124],
  [172, 1, 124],
  [20, 171, 124],
  [46, 35, 37, 124],
  [5, 297, 124],
  [197, 88, 124],
  [247, 88, 124],
  [46, 88, 122, 124],
  [133, 152, 124],
  [88, 88, 1, 26, 124],
  [125, 5, 297, 124],
  [43, 116, 43, 116, 124],
  [88, 46, 89, 88, 124],
  [257, 1, 88, 108, 124],
  [306, 49, 75, 124],
  [293, 103, 80, 124],
  [317, 217, 124],
  [260, 59, 34, 34, 34, 124],
  [306, 43, 5],
  [306, 37, 56],
  [306, 103, 88],
  [306, 99, 88],
  [306, 133, 122],
  [306, 164, 88],
  [306, 61, 35, 100],
  [306, 75, 230],
  [306, 46, 88, 122],
  [306, 46, 34, 34, 17],
  [306, 136, 152],
  [306, 152, 235],
  [306, 89, 34, 221],
  [306, 106, 187, 88],
  [22, 31, 75, 39, 59, 306],
  [192, 278, 82, 306],
  [306, 46, 88, 88, 201, 175],
  [286, 30, 1, 18, 51, 306, 35, 57],
  [168, 137, 218],
  [317, 222, 71, 108, 17],
  [309, 62, 14, 8],
  [152, 1, 309],
  [202, 161, 19, 309],
  [170, 67, 108],
  [344, 43, 5],
  [344, 59, 5],
  [344, 34, 1, 14],
  [344, 121, 4],
  [344, 43, 16],
  [344, 34, 1, 62],
  [344, 17, 88],
  [344, 20, 37, 5],
  [344, 20, 91],
  [344, 43, 111],
  [344, 35, 88],
  [344, 43, 88],
  [344, 46, 170],
  [344, 99, 88],
  [344, 29, 1, 88],
  [344, 36, 129],
  [344, 4, 202],
  [344, 103, 88],
  [344, 133, 26],
  [344, 35, 88, 38],
  [344, 121, 278],
  [344, 49, 191, 4],
  [344, 71, 34, 34],
  [344, 88, 4, 88],
  [344, 247, 17],
  [344, 99, 165],
  [344, 216, 58],
  [344, 109, 17, 88],
  [344, 29, 108, 90],
  [344, 123, 199],
  [344, 168, 189],
  [344, 107, 1, 151],
  [344, 147, 147],
  [344, 240, 91],
  [344, 149, 217],
  [344, 152, 177],
  [344, 136, 152],
  [344, 122, 221],
  [344, 132, 175],
  [344, 305, 49],
  [344, 277, 88],
  [344, 106, 135, 59],
  [344, 238, 39, 59],
  [344, 165, 57, 75],
  [344, 237, 180],
  [344, 168, 1, 20, 59],
  [344, 296, 119],
  [344, 294, 75],
  [344, 46, 49, 51, 46, 41],
  [344, 107, 35, 34, 88],
  [344, 121, 312],
  [344, 17, 272, 35],
  [344, 285, 137],
  [344, 46, 41, 31, 31, 75],
  [344, 302, 182],
  [344, 217, 300],
  [344, 46, 49, 29, 17, 88],
  [106, 196, 21, 21, 344],
  [344, 262, 59, 71],
  [344, 192, 278, 82],
  [344, 121, 51, 88, 71],
  [344, 154, 222, 59],
  [344, 179, 19, 235],
  [344, 247, 152, 38],
  [344, 263, 203],
  [344, 250, 314],
  [344, 71, 71, 71, 1, 38],
  [344, 247, 309],
  [344, 106, 363],
  [344, 222, 43, 274],
  [344, 329, 217],
  [344, 17, 147, 88, 4, 71],
  [344, 222, 71, 108, 17],
  [344, 121, 51, 88, 217],
  [344, 72, 14, 73, 1, 88, 82],
  [344, 262, 31, 1, 221],
  [262, 31, 1, 222, 344],
  [344, 240, 127, 127, 137],
  [344, 106, 196, 21, 21, 180],
  [344, 34, 1, 88, 88, 88, 228],
  [344, 46, 88, 88, 201, 175],
  [344, 222, 300, 342],
  [344, 164, 38, 164, 38, 312],
  [319, 300, 300, 344],
  [229, 108, 17, 286, 39, 59, 344],
  [336, 1, 77],
  [336, 99, 88],
  [336, 34, 1, 88],
  [336, 34, 147],
  [336, 71, 71],
  [336, 88, 4, 88],
  [336, 35, 88, 38],
  [336, 42, 286],
  [336, 88, 88, 88],
  [336, 115, 88, 80],
  [336, 199, 95, 88],
  [336, 257, 1, 75],
  [336, 305, 49],
  [336, 17, 272, 35],
  [336, 71, 61, 221],
  [336, 127, 127, 256],
  [336, 109, 88, 247, 17],
  [4, 234, 17],
  [124, 19, 59],
  [124, 16, 38],
  [124, 46, 41],
  [124, 25, 88],
  [124, 107, 21],
  [124, 34, 82],
  [124, 208, 13],
  [124, 1, 240],
  [124, 99, 80],
  [124, 21, 21, 71],
  [124, 75, 36, 36],
  [124, 71, 151],
  [124, 107, 16, 38],
  [124, 34, 1, 137],
  [124, 247, 88],
  [124, 34, 1, 14, 16, 61],
  [124, 71, 35, 100],
  [124, 43, 279],
  [124, 42, 286],
  [124, 71, 35, 71],
  [124, 152, 1, 177],
  [124, 99, 1, 235],
  [124, 20, 73, 278],
  [124, 240, 129],
  [124, 21, 21, 240],
  [124, 1, 88, 29, 108, 3],
  [124, 88, 312],
  [124, 49, 1, 35, 221],
  [124, 121, 240, 121],
  [124, 278, 59, 203],
  [124, 247, 152, 17],
  [124, 108, 290, 182],
  [124, 20, 73, 278, 182],
  [124, 34, 1, 88, 34, 88, 34],
  [124, 168, 73, 114, 185],
  [137, 203, 345],
  [19, 34, 342],
  [20, 61, 342],
  [31, 75, 342],
  [151, 21, 342],
  [46, 125, 342],
  [29, 71, 88, 342],
  [88, 274, 342],
  [342, 342, 59],
  [43, 116, 43, 116, 342],
  [46, 34, 34, 137, 342],
  [168, 127, 75, 342],
  [179, 56, 231, 342],
  [329, 16, 61],
  [329, 34, 1, 62],
  [329, 43, 111],
  [329, 121, 278],
  [329, 4, 71, 119],
  [329, 19, 16, 120],
  [329, 31, 198],
  [329, 247, 118],
  [329, 199, 28, 88],
  [329, 286, 38],
  [329, 137, 137],
  [329, 137, 215],
  [329, 252, 52, 59],
  [329, 258, 161, 58],
  [329, 199, 168, 4, 71],
  [329, 88, 151, 99, 88],
  [329, 348, 171],
  [329, 109, 88, 247, 17],
  [329, 318, 309],
  [329, 19, 237, 237, 151],
  [329, 319, 152, 43, 34, 13],
  [329, 88, 88, 88, 73, 34, 34],
  [247, 132, 171],
  [132, 171, 107, 1, 151],
  [132, 171, 168, 189],
  [164, 88, 338],
  [106, 138, 138, 338],
  [19, 152, 278, 142, 349],
  [348, 34, 21],
  [348, 152, 1],
  [348, 71, 71],
  [348, 107, 118],
  [73, 105, 348],
  [348, 121, 278],
  [348, 43, 293],
  [348, 258, 137],
  [348, 237, 180],
  [348, 34, 304],
  [348, 1, 217, 1, 217, 1],
  [348, 71, 294, 182],
  [348, 263, 75, 54, 182],
  [115, 88, 80],
  [115, 88, 80, 57, 5],
  [17, 152, 17, 115, 88, 80],
  [115, 88, 80, 168, 286],
  [247, 152, 57, 88],
  [247, 152, 88, 312],
  [5, 297, 125, 124, 247, 152],
  [1, 4, 312],
  [25, 1, 4, 312],
  [21, 1, 4, 312],
  [73, 1, 4, 312],
  [128, 1, 4, 312],
  [97, 1, 4, 312],
  [129, 1, 4, 312],
  [35, 61, 1, 4, 312],
  [62, 14, 8, 1, 4, 312],
  [16, 38, 1, 4, 312],
  [35, 57, 1, 4, 312],
  [145, 1, 4, 312],
  [255, 1, 4, 312],
  [34, 1, 62, 1, 4, 312],
  [72, 88, 1, 4, 312],
  [301, 1, 4, 312],
  [1, 88, 108, 1, 4, 312],
  [75, 34, 34, 1, 4, 312],
  [34, 1, 14, 88, 1, 4, 312],
  [1, 128, 73, 1, 4, 312],
  [237, 38, 1, 4, 312],
  [151, 87, 1, 4, 312],
  [333, 1, 4, 312],
  [152, 1, 151, 1, 4, 312],
  [107, 99, 88, 1, 4, 312],
  [88, 88, 1, 26, 1, 4, 312],
  [179, 19, 97, 1, 4, 312],
  [90, 266, 1, 4, 312],
  [19, 240, 121, 1, 4, 312],
  [17, 272, 35, 1, 4, 312],
  [293, 142, 1, 4, 312],
  [169, 342, 1, 4, 312],
  [46, 89, 88, 152, 2, 1, 4, 312],
  [152, 127, 127, 180, 1, 4, 312],
  [151, 87, 1, 4, 312, 343],
  [271, 217, 221, 1, 4, 312],
  [40, 1, 274],
  [247, 40, 1, 274],
  [209, 40, 1, 274, 61, 88],
  [40, 1, 274, 95, 88],
  [40, 1, 274, 272, 35],
  [40, 1, 274, 152, 1, 177],
  [40, 1, 274, 286, 3, 59],
  [263, 203, 40, 1, 274],
  [354, 31, 58],
  [354, 19, 59],
  [354, 42, 34],
  [354, 61, 88],
  [354, 9, 1, 88],
  [354, 43, 111],
  [354, 31, 91],
  [354, 46, 170],
  [354, 108, 77],
  [354, 4, 29, 88],
  [143, 59, 354],
  [354, 168, 118],
  [354, 34, 1, 137],
  [354, 152, 21, 21],
  [354, 147, 147],
  [354, 42, 286],
  [354, 107, 234],
  [354, 217, 1, 120],
  [354, 44, 5, 57, 217],
  [354, 286, 3, 59],
  [354, 154, 222, 59],
  [354, 127, 127, 147, 34],
  [354, 71, 71, 71, 1, 38],
  [19, 152, 278, 142, 354],
  [354, 42, 88, 21, 21, 42, 225, 5],
  [237, 152, 31, 152, 100],
  [72, 195, 39, 59, 237, 152],
  [359, 37, 37],
  [118, 59, 359],
  [359, 43, 88],
  [58, 88, 359],
  [359, 34, 82],
  [359, 107, 21],
  [359, 89, 35],
  [359, 108, 77],
  [359, 99, 88],
  [359, 61, 35, 100],
  [359, 218, 26],
  [359, 75, 25, 88],
  [258, 161, 359],
  [359, 169, 228],
  [107, 201, 35, 359],
  [171, 49, 131, 359],
  [359, 59, 5, 5, 274],
  [359, 217, 300],
  [149, 152, 75, 359],
  [359, 263, 203],
  [359, 23, 88, 88, 88],
  [359, 4, 75, 88, 29, 88],
  [359, 34, 1, 88, 34, 88, 34],
  [150, 43, 88, 161, 359],
  [359, 222, 71, 108, 17],
  [359, 260, 59, 34, 34, 34],
  [359, 271, 217, 221],
  [359, 86, 21, 217, 136, 35],
  [359, 330, 106, 196, 21, 21],
  [277, 278, 46, 39],
  [277, 278, 39, 59],
  [277, 278, 17, 88],
  [277, 278, 292],
  [277, 278, 99, 88],
  [277, 278, 371],
  [277, 278, 149, 143, 21, 77],
  [277, 278, 20, 73, 278, 182],
  [277, 278, 273, 1, 88, 108],
  [46, 88, 29, 88],
  [323, 97, 162],
  [323, 97, 151, 21],
  [323, 97, 20, 37, 5],
  [323, 97, 226],
  [323, 97, 72, 88],
  [323, 97, 107, 203],
  [323, 97, 137, 35, 61],
  [323, 97, 17, 88, 278],
  [323, 97, 97, 1, 4, 312],
  [323, 97, 107, 1, 87, 312],
  [360, 46, 91],
  [360, 136, 35],
  [137, 279, 137, 51, 289, 56, 21, 97],
  [1, 88, 29, 108, 3],
  [362, 50, 17],
  [16, 61, 362],
  [237, 118, 362],
  [106, 138, 138, 362],
  [19, 152, 278, 142, 362],
  [42, 217, 180],
  [42, 217, 180, 152],
  [42, 217, 180, 76, 5],
  [42, 217, 180, 43, 111],
  [42, 217, 180, 20, 171],
  [42, 217, 180, 46, 170],
  [42, 217, 180, 294],
  [42, 217, 180, 309],
  [199, 121, 4, 42, 217, 180],
  [42, 217, 180, 152, 152],
  [42, 217, 180, 46, 88, 121],
  [42, 217, 180, 297, 54],
  [42, 217, 180, 237, 180],
  [42, 217, 180, 217, 185],
  [42, 217, 180, 222, 3, 181],
  [42, 217, 180, 133, 21, 152],
  [42, 217, 180, 302, 182],
  [42, 217, 180, 154, 222, 59],
  [42, 217, 180, 263, 203],
  [321, 161, 42, 217, 180],
  [42, 217, 180, 294, 108, 88],
  [42, 217, 180, 19, 108, 116, 42, 34],
  [42, 217, 180, 293, 103, 80],
  [42, 217, 180, 129, 88, 88, 88, 88],
  [42, 217, 180, 271, 217, 221],
  [63, 316, 180],
  [40, 1, 316, 180],
  [88, 316, 180],
  [83, 316, 180],
  [184, 316, 180],
  [148, 316, 180],
  [34, 1, 62, 316, 180],
  [316, 180, 107, 21],
  [103, 54, 316, 180],
  [17, 88, 316, 180],
  [224, 316, 180],
  [219, 316, 180],
  [199, 73, 316, 180],
  [34, 1, 88, 316, 180],
  [88, 278, 316, 180],
  [313, 316, 180],
  [164, 88, 316, 180],
  [1, 83, 151, 316, 180],
  [75, 230, 316, 180],
  [171, 171, 316, 180],
  [136, 152, 316, 180],
  [46, 88, 122, 316, 180],
  [180, 180, 51, 316, 180],
  [364, 316, 180],
  [278, 279, 316, 180],
  [106, 135, 180, 316, 180],
  [23, 88, 88, 88, 316, 180],
  [127, 121, 127, 121, 316, 180],
  [106, 34, 342, 316, 180],
  [88, 151, 99, 88, 316, 180],
  [312, 312, 118, 316, 180],
  [319, 300, 300, 316, 180],
  [28, 89, 289, 213, 1, 88],
  [28, 89, 289, 34, 1, 88, 34, 88, 34],
  [262, 31, 28, 89, 289, 221],
  [106, 196, 21, 21],
  [106, 196, 21, 21, 39],
  [106, 196, 21, 21, 293],
  [106, 196, 21, 21, 261, 35],
  [137, 137, 106, 196, 21, 21],
  [330, 106, 196, 21, 21],
  [106, 196, 21, 21, 315, 82],
  [106, 196, 21, 21, 293, 103, 80],
  [17, 34, 34, 34, 103],
  [17, 34, 34, 34, 100, 131],
  [17, 34, 34, 34, 100, 43, 293],
  [17, 34, 34, 34, 100, 349],
  [106, 138, 138],
  [106, 138, 138, 127],
  [106, 138, 138, 162],
  [135, 1, 217, 35],
  [237, 34, 199],
  [237, 43, 4, 34, 199],
  [237, 34, 199, 28, 88],
  [378, 34, 1, 14],
  [34, 83, 378],
  [378, 116, 56],
  [378, 127, 58],
  [378, 28, 88],
  [378, 72, 88],
  [121, 51, 88, 378],
  [378, 247, 152],
  [96, 90, 1, 378, 161],
  [378, 72, 222, 312],
  [72, 46, 41, 161, 377],
  [72, 88, 108, 17, 59],
  [72, 88, 108, 17, 59, 99, 50],
  [72, 88, 108, 17, 59, 343],
  [286, 322, 218],
  [286, 322, 23, 152, 118],
  [285, 217, 78],
  [285, 217, 78, 67],
  [381, 151, 21],
  [381, 34, 1, 62],
  [381, 43, 88],
  [381, 88, 151],
  [381, 144, 88],
  [381, 109, 268],
  [383, 136, 35],
]
export const VALIDGUESS_CHAR_TO_SYMBOLS: number[][] = [
  [1],
  [25],
  [24],
  [79],
  [1, 16],
  [28, 1],
  [1, 28],
  [141],
  [134],
  [193],
  [216],
  [141, 1],
  [205],
  [239],
  [208],
  [129, 61],
  [108, 229],
  [3, 49],
  [156],
  [310],
  [104],
  [173],
  [5, 129],
  [60],
  [101],
  [4, 61],
  [183],
  [236],
  [242],
  [4, 183],
  [239, 4],
  [239, 5],
  [7],
  [63],
  [31, 7],
  [117],
  [65],
  [328],
  [16],
  [68],
  [16, 61],
  [1, 123],
  [144],
  [130],
  [267],
  [325],
  [46, 13],
  [46, 170],
  [292],
  [46, 174],
  [34],
  [34, 17],
  [34, 16],
  [34, 25],
  [19, 34],
  [34, 28],
  [34, 63],
  [34, 32],
  [34, 60],
  [34, 122],
  [34, 72],
  [34, 117],
  [34, 79],
  [34, 82],
  [34, 90],
  [34, 95],
  [34, 83],
  [214],
  [34, 156],
  [34, 164],
  [34, 1, 72],
  [34, 151],
  [34, 197],
  [34, 144],
  [34, 142],
  [34, 147],
  [34, 137],
  [34, 131],
  [34, 180],
  [34, 240],
  [34, 220],
  [34, 214],
  [34, 217],
  [34, 247],
  [34, 5, 129],
  [34, 253],
  [34, 236],
  [34, 243],
  [34, 264],
  [34, 291],
  [34, 304],
  [34, 313],
  [34, 309],
  [34, 319],
  [34, 338],
  [34, 343],
  [34, 323],
  [34, 336],
  [34, 71, 108, 17],
  [34, 350],
  [34, 356],
  [34, 362],
  [34, 372],
  [34, 365],
  [1, 38],
  [61, 38],
  [16, 38],
  [88, 38],
  [84, 38],
  [38, 181],
  [164, 38],
  [42, 225],
  [286, 38],
  [232, 38],
  [36],
  [159],
  [36, 129],
  [35],
  [35, 61],
  [46, 35],
  [35, 26],
  [136, 35],
  [239, 35],
  [261, 35],
  [272, 35],
  [335],
  [363],
  [226],
  [227],
  [1, 226],
  [218, 154],
  [154, 215],
  [51, 39],
  [51, 332],
  [99, 50],
  [48, 199],
  [48, 342],
  [48, 327],
  [39],
  [39, 5],
  [37, 56],
  [233],
  [232],
  [116, 56],
  [57],
  [9, 2],
  [57, 5],
  [35, 57],
  [24, 57],
  [37, 57],
  [67, 57],
  [177, 57],
  [147, 57],
  [143, 57],
  [291, 57],
  [237, 57],
  [227, 57],
  [268, 57],
  [282, 57],
  [265, 57],
  [292, 57],
  [312, 57],
  [305, 57],
  [380, 57],
  [58],
  [73, 58],
  [58, 88],
  [216, 58],
  [292, 58],
  [348, 58],
  [352, 58],
  [365, 58],
  [43, 16],
  [43, 61],
  [177],
  [43, 111],
  [43, 98],
  [43, 303],
  [21],
  [34, 21],
  [86, 21],
  [22, 91],
  [22, 167],
  [22, 129],
  [22, 219],
  [22, 338],
  [23, 35],
  [17],
  [95],
  [140],
  [4, 77],
  [31, 17],
  [17, 77],
  [49, 69],
  [343],
  [17, 29, 49, 67],
  [28],
  [46, 28],
  [28, 88],
  [102, 54],
  [168, 54],
  [297, 54],
  [283, 54],
  [19, 54],
  [71, 61],
  [59],
  [59, 5],
  [178],
  [20, 59],
  [19, 59],
  [260, 59],
  [88],
  [17, 88],
  [43, 88],
  [88, 58],
  [88, 57],
  [88, 54],
  [88, 35],
  [88, 55],
  [57, 88],
  [88, 35],
  [88, 25],
  [25, 88],
  [61, 88],
  [88, 45],
  [223],
  [20, 88],
  [22, 88],
  [88, 68],
  [99, 88],
  [88, 127],
  [72, 88],
  [88, 91],
  [88, 82],
  [103, 88],
  [264],
  [88, 71],
  [88, 94],
  [197, 88],
  [179, 88],
  [88, 142],
  [141, 88],
  [88, 194],
  [88, 159],
  [88, 178],
  [88, 177],
  [144, 88],
  [88, 148],
  [88, 137],
  [164, 88],
  [88, 241],
  [88, 202],
  [88, 219],
  [88, 220],
  [88, 242],
  [88, 216],
  [88, 236],
  [237, 88],
  [88, 270],
  [88, 293],
  [88, 285],
  [88, 292],
  [88, 263],
  [88, 312],
  [88, 313],
  [88, 309],
  [88, 303],
  [88, 319],
  [342, 88],
  [88, 342],
  [88, 351],
  [88, 332],
  [336, 88],
  [88, 325],
  [88, 338],
  [88, 343],
  [376],
  [88, 359],
  [88, 361],
  [88, 366],
  [88, 368],
  [88, 371],
  [88, 378],
  [88, 383],
  [89, 34],
  [89, 35],
  [89, 88],
  [89, 75],
  [89, 145],
  [89, 137],
  [89, 303],
  [71],
  [71, 71],
  [71, 111],
  [71, 117],
  [71, 128],
  [71, 178],
  [71, 151],
  [71, 173],
  [71, 141],
  [71, 255],
  [71, 220],
  [71, 204],
  [71, 33, 18, 5],
  [351],
  [71, 247],
  [71, 297],
  [71, 304],
  [71, 309],
  [71, 303],
  [71, 343],
  [71, 342],
  [325, 71],
  [71, 362],
  [71, 361],
  [378, 71],
  [383, 71],
  [72],
  [1, 72],
  [192, 72],
  [373],
  [103],
  [103, 28],
  [40, 143],
  [103, 103],
  [75],
  [1, 75],
  [75, 5],
  [131],
  [4, 75],
  [224],
  [4, 131],
  [270],
  [75, 230],
  [75, 203],
  [200, 69],
  [75, 323],
  [118],
  [118, 59],
  [118, 60],
  [118, 67],
  [118, 117],
  [118, 122],
  [118, 88],
  [118, 110],
  [118, 1, 72],
  [118, 169],
  [192, 118],
  [118, 193],
  [168, 118],
  [118, 218],
  [118, 202],
  [337],
  [247, 118],
  [118, 198],
  [118, 245],
  [118, 227],
  [118, 216],
  [118, 214],
  [118, 235],
  [237, 118],
  [108, 71, 118],
  [118, 270],
  [118, 268],
  [118, 301],
  [118, 309],
  [118, 313],
  [371],
  [118, 343],
  [118, 359],
  [118, 363],
  [118, 376],
  [122],
  [122],
  [126],
  [122, 7],
  [60, 122],
  [107, 122],
  [168, 122],
  [122, 161],
  [133, 122],
  [122, 221],
  [237, 122],
  [122, 241],
  [122, 292],
  [107, 21],
  [107, 94],
  [107, 68],
  [107, 82],
  [107, 118],
  [107, 137],
  [107, 203],
  [107, 234],
  [107, 218],
  [107, 216],
  [107, 268],
  [107, 262],
  [107, 307],
  [107, 350],
  [107, 383],
  [82],
  [72, 82],
  [315, 82],
  [326, 82],
  [121],
  [121, 4],
  [121, 75],
  [76, 5],
  [109],
  [197],
  [109, 6],
  [109, 21],
  [109, 162],
  [109, 199],
  [109, 268],
  [109, 293],
  [109, 371],
  [145],
  [90],
  [90, 178],
  [90, 209],
  [90, 218],
  [239, 90],
  [90, 254],
  [90, 313],
  [316, 90],
  [90, 342],
  [128],
  [295],
  [182, 128],
  [128, 333],
  [73],
  [20, 73],
  [73, 26],
  [73, 52],
  [110],
  [112],
  [111],
  [194],
  [91],
  [46, 91],
  [20, 91],
  [91, 240],
  [240, 91],
  [91, 323],
  [367],
  [91, 342],
  [67],
  [67, 49],
  [281],
  [108, 77],
  [71, 108, 17],
  [127, 9],
  [127, 58],
  [106, 137],
  [106, 251],
  [106, 294],
  [106, 302],
  [106, 345],
  [372],
  [106, 341],
  [106, 363],
  [106, 260, 4, 71, 20, 222, 1, 185],
  [296, 119],
  [135],
  [61, 77],
  [129, 77],
  [83, 73],
  [113],
  [191],
  [113, 3],
  [253],
  [113, 61],
  [110, 117],
  [113, 241],
  [113, 260],
  [113, 323],
  [113, 376],
  [173, 97],
  [96, 255],
  [96, 253],
  [96, 46, 71],
  [96, 297],
  [96, 296],
  [96, 338],
  [185],
  [248],
  [110, 185],
  [185, 82],
  [72, 185],
  [156, 185],
  [185, 190],
  [185, 184],
  [177, 185],
  [236, 185],
  [185, 224],
  [185, 240],
  [217, 185],
  [185, 235],
  [185, 267],
  [294, 185],
  [260, 185],
  [185, 297],
  [285, 185],
  [317, 185],
  [185, 305],
  [310, 185],
  [338, 185],
  [185, 323],
  [336, 185],
  [185, 337],
  [185, 342],
  [326, 185],
  [325, 185],
  [350, 185],
  [185, 362],
  [185, 376],
  [147],
  [213],
  [213, 1],
  [213, 5],
  [147, 20],
  [213, 9],
  [313],
  [147, 77],
  [192, 147],
  [376, 147],
  [169],
  [169, 142],
  [169, 167],
  [169, 228],
  [169, 299],
  [169, 338],
  [160],
  [85],
  [160, 7],
  [160, 35],
  [160, 25],
  [160, 60],
  [160, 94],
  [160, 73],
  [160, 88],
  [160, 193],
  [160, 151],
  [160, 131],
  [160, 147],
  [346],
  [160, 190],
  [160, 194],
  [160, 168],
  [160, 167],
  [160, 255],
  [160, 202],
  [160, 219],
  [160, 218],
  [160, 254],
  [160, 253],
  [160, 46, 71],
  [160, 33, 18, 5],
  [160, 198],
  [160, 247],
  [160, 240],
  [160, 209],
  [160, 275],
  [160, 303],
  [160, 336],
  [160, 351],
  [160, 334],
  [160, 338],
  [160, 342],
  [160, 371],
  [160, 380],
  [160, 383],
  [17, 59],
  [55, 161],
  [110, 161],
  [73, 161],
  [308, 161],
  [312, 161],
  [321, 161],
  [324, 161],
  [371, 161],
  [179],
  [338, 179],
  [50, 17],
  [167],
  [167, 5],
  [170, 167],
  [209, 167],
  [302, 167],
  [46, 41],
  [297, 146],
  [152],
  [152, 1],
  [21, 152],
  [152, 17],
  [43, 152],
  [63, 152],
  [152, 67],
  [152, 129],
  [152, 152],
  [152, 171],
  [172, 152],
  [152, 177],
  [136, 152],
  [152, 235],
  [152, 224],
  [200, 152],
  [152, 202],
  [152, 236],
  [152, 263],
  [296, 152],
  [152, 384],
  [153],
  [273],
  [275],
  [304],
  [296, 152],
  [368],
  [171],
  [20, 171],
  [171, 171],
  [171, 383],
  [137],
  [202],
  [202],
  [206],
  [137, 7],
  [138, 5],
  [4, 202],
  [137, 28],
  [39, 137],
  [137, 26],
  [137, 97],
  [137, 122],
  [137, 88],
  [137, 85],
  [137, 82],
  [137, 79],
  [137, 71],
  [137, 110],
  [305],
  [137, 141],
  [137, 180],
  [327],
  [137, 152],
  [137, 31, 17],
  [137, 194],
  [137, 129],
  [137, 167],
  [137, 184],
  [137, 137],
  [137, 161],
  [333],
  [137, 208],
  [137, 240],
  [204, 137],
  [137, 204],
  [258, 137],
  [137, 218],
  [137, 236],
  [305, 49],
  [137, 46, 71],
  [137, 227],
  [263, 137],
  [137, 299],
  [137, 292],
  [137, 297],
  [137, 304],
  [316, 137],
  [369],
  [265, 265],
  [265, 265],
  [137, 327],
  [137, 345],
  [137, 337],
  [137, 333],
  [137, 342],
  [137, 364],
  [137, 362],
  [137, 368],
  [137, 371],
  [42, 34],
  [151],
  [1, 151],
  [151, 21],
  [151, 87],
  [143],
  [143, 21],
  [143, 224],
  [198],
  [198],
  [31, 198],
  [132, 198],
  [21, 21],
  [162],
  [172],
  [172, 1],
  [254],
  [163, 60],
  [163, 253],
  [163, 293],
  [163, 292],
  [163, 294],
  [199],
  [249],
  [199, 54],
  [199, 25],
  [199, 17],
  [308],
  [199, 103],
  [199, 90],
  [199, 67],
  [199, 68],
  [199, 118],
  [73, 199],
  [199, 73],
  [199, 117],
  [199, 152],
  [199, 129],
  [199, 178],
  [199, 190],
  [199, 163],
  [199, 185],
  [199, 184],
  [199, 145],
  [199, 137],
  [199, 156],
  [199, 139],
  [199, 202],
  [199, 216],
  [199, 253],
  [199, 218],
  [199, 205],
  [240, 199],
  [199, 240],
  [199, 248],
  [199, 255],
  [199, 247],
  [199, 46, 71],
  [200, 199],
  [199, 224],
  [199, 249],
  [199, 294],
  [199, 296],
  [199, 260],
  [199, 295],
  [199, 303],
  [199, 309],
  [199, 337],
  [199, 342],
  [199, 367],
  [199, 368],
  [199, 366],
  [199, 380],
  [180],
  [20, 180],
  [180, 71],
  [101, 180],
  [128, 180],
  [180, 180],
  [278, 180],
  [180, 208],
  [180, 236],
  [356],
  [180, 294],
  [361],
  [339, 180],
  [342, 180],
  [180, 379],
  [168],
  [168, 194],
  [168, 189],
  [170],
  [170, 194],
  [37, 37],
  [166],
  [166, 343],
  [148],
  [164],
  [164, 21],
  [61, 164],
  [164, 117],
  [164, 71],
  [107, 164],
  [164, 161],
  [164, 177],
  [164, 235],
  [320, 164],
  [164, 384],
  [142],
  [142, 54],
  [192, 142],
  [142, 129],
  [142, 180],
  [142, 219],
  [142, 241],
  [142, 216],
  [142, 297],
  [142, 309],
  [142, 312],
  [142, 333],
  [46, 127],
  [129, 5],
  [129],
  [129, 101],
  [129, 179],
  [129, 129],
  [129, 161],
  [129, 255],
  [129, 240],
  [129, 227],
  [129, 311],
  [129, 308],
  [129, 309],
  [129, 332],
  [129, 359],
  [129, 362],
  [129, 383],
  [241],
  [212],
  [204],
  [235],
  [304, 235],
  [243],
  [244],
  [303],
  [141, 243],
  [217],
  [218],
  [219],
  [220],
  [217, 58],
  [43, 217],
  [217, 128],
  [370],
  [317, 217],
  [14, 151],
  [246, 101],
  [246, 90],
  [246, 194],
  [246, 255],
  [246, 204],
  [246, 216],
  [246, 208],
  [246, 297],
  [246, 338],
  [246, 343],
  [240],
  [1, 240],
  [240, 24],
  [240, 129],
  [255],
  [221],
  [68, 221],
  [141, 221],
  [224, 221],
  [215],
  [215, 25],
  [17, 272],
  [137, 215],
  [215, 145],
  [195, 215],
  [160, 215],
  [215, 254],
  [215, 297],
  [215, 351],
  [215, 343],
  [215, 334],
  [215, 383],
  [258],
  [31, 75],
  [209],
  [209, 103],
  [209, 255],
  [209, 220],
  [209, 304],
  [209, 311],
  [209, 343],
  [209, 335],
  [209, 364],
  [209, 359],
  [203],
  [203, 71],
  [203, 111],
  [203, 124],
  [203, 172],
  [203, 167],
  [203, 151],
  [203, 248],
  [203, 216],
  [203, 236],
  [203, 220],
  [203, 294],
  [263, 203],
  [203, 376],
  [353],
  [237],
  [237, 38],
  [237, 60],
  [237, 61],
  [340],
  [237, 180],
  [237, 216],
  [237, 202],
  [200, 237],
  [237, 224],
  [237, 4, 131],
  [237, 342],
  [237, 352],
  [107, 35],
  [247],
  [247, 309],
  [284],
  [284, 16],
  [284, 68],
  [284, 67],
  [284, 194],
  [284, 235],
  [284, 218],
  [284, 247],
  [284, 206],
  [284, 296],
  [284, 265],
  [284, 359],
  [284, 371],
  [284, 376],
  [284, 383],
  [293],
  [293, 247],
  [293, 216],
  [263, 293],
  [293, 304],
  [293, 368],
  [300],
  [4, 300],
  [300, 55],
  [300, 110],
  [300, 82],
  [300, 73],
  [179, 300],
  [300, 179],
  [300, 159],
  [300, 193],
  [300, 145],
  [300, 172],
  [300, 178],
  [132, 300],
  [217, 300],
  [300, 217],
  [300, 220],
  [300, 253],
  [300, 216],
  [300, 300],
  [300, 351],
  [300, 342],
  [300, 349],
  [300, 363],
  [300, 371],
  [300, 377],
  [279],
  [279, 73],
  [279, 190],
  [279, 206],
  [222, 338],
  [222, 359],
  [294],
  [294, 38],
  [294, 75],
  [294, 180],
  [294, 313],
  [384],
  [299],
  [299, 77],
  [299, 247],
  [299, 240],
  [294, 299],
  [299, 342],
  [338, 299],
  [133, 21],
  [133, 26],
  [133, 152],
  [266],
  [266, 118],
  [266, 82],
  [280],
  [280, 130],
  [280, 162],
  [280, 194],
  [280, 234],
  [260],
  [260, 124],
  [260, 184],
  [260, 180],
  [260, 88, 95, 1],
  [336, 260],
  [383, 260],
  [296],
  [250, 314],
  [323, 296],
  [278],
  [278, 58],
  [278, 39],
  [121, 278],
  [278, 82],
  [278, 71],
  [278, 73],
  [278, 67],
  [278, 194],
  [169, 278],
  [278, 145],
  [151, 278],
  [84, 278],
  [278, 139],
  [217, 278],
  [218, 278],
  [278, 219],
  [278, 270],
  [278, 303],
  [278, 323],
  [278, 343],
  [278, 335],
  [278, 352],
  [278, 380],
  [262],
  [262, 34],
  [285],
  [285, 137],
  [285, 142],
  [268],
  [268, 100],
  [286],
  [341],
  [168, 286],
  [200, 286],
  [67, 88],
  [103, 80],
  [287],
  [287, 90],
  [287, 240],
  [297],
  [5, 297],
  [366, 297],
  [42, 194],
  [149, 37],
  [149, 68],
  [149, 131],
  [149, 183],
  [149, 194],
  [149, 167],
  [149, 148],
  [149, 226],
  [149, 217],
  [149, 215],
  [149, 224],
  [149, 213],
  [149, 258],
  [149, 202],
  [149, 260],
  [149, 34, 1, 72],
  [149, 338],
  [149, 337],
  [149, 350],
  [149, 341],
  [149, 352],
  [149, 362],
  [149, 379],
  [271, 38],
  [271, 81],
  [271, 179],
  [271, 276],
  [274],
  [274, 73],
  [274, 179],
  [274, 165],
  [274, 239],
  [274, 236],
  [274, 204],
  [274, 216],
  [274, 268],
  [274, 313],
  [354, 274],
  [274, 359],
  [274, 371],
  [274, 376],
  [274, 377],
  [4, 221],
  [96, 1, 25],
  [96, 199, 16, 8],
  [96, 71, 71, 16, 8],
  [96, 144, 88, 16, 8],
  [96, 115, 88, 80, 16, 8],
  [96, 352, 16, 8],
  [96, 42, 217, 75, 16, 8],
  [96, 215, 215, 342, 16, 8],
  [291],
  [132, 175],
  [291, 97],
  [291, 190],
  [291, 218],
  [291, 255],
  [316, 291],
  [308, 291],
  [291, 303],
  [291, 309],
  [291, 343],
  [338, 291],
  [291, 333],
  [291, 371],
  [383, 291],
  [263],
  [263, 118],
  [311],
  [131, 311],
  [168, 311],
  [203, 311],
  [365, 311],
  [319],
  [319, 25],
  [319, 28],
  [319, 17],
  [319, 73],
  [319, 82],
  [319, 67],
  [319, 128],
  [319, 90],
  [319, 94],
  [319, 110],
  [319, 148],
  [319, 190],
  [319, 159],
  [319, 31, 17],
  [319, 236],
  [319, 216],
  [319, 249],
  [319, 292],
  [319, 294],
  [319, 4, 202],
  [319, 342],
  [319, 333],
  [319, 363],
  [319, 365],
  [307],
  [278, 307],
  [318, 85],
  [318, 309],
  [312],
  [28, 312],
  [42, 312],
  [312, 85],
  [73, 312],
  [198, 312],
  [132, 312],
  [222, 312],
  [253, 312],
  [34, 67, 1, 312],
  [312, 292],
  [263, 312],
  [312, 323],
  [312, 363],
  [71, 174],
  [71, 151],
  [88, 151],
  [88, 151, 4, 131],
  [315],
  [315, 113],
  [302],
  [302, 7],
  [302, 63],
  [51, 302],
  [302, 67],
  [302, 218],
  [302, 4, 131],
  [302, 268],
  [302, 303],
  [338, 302],
  [247, 17],
  [182, 68],
  [182, 117],
  [182, 167],
  [218, 182],
  [240, 182],
  [293, 182],
  [263, 182],
  [297, 182],
  [307, 182],
  [319, 182],
  [305, 182],
  [302, 182],
  [345, 182],
  [342, 182],
  [350, 182],
  [368, 182],
  [88, 194],
  [165, 124],
  [148, 124],
  [239, 124],
  [351, 124],
  [306],
  [306, 25],
  [49, 306],
  [306, 110],
  [199, 306],
  [306, 204],
  [306, 237],
  [306, 295],
  [306, 362],
  [306, 379],
  [168, 137],
  [309],
  [352],
  [344],
  [344, 57],
  [344, 25],
  [344, 17],
  [344, 88],
  [344, 128],
  [344, 159],
  [344, 145],
  [344, 193],
  [344, 134],
  [344, 218],
  [344, 219],
  [344, 240],
  [344, 204],
  [344, 297],
  [96, 344, 16, 8],
  [344, 303],
  [344, 342],
  [344, 351],
  [344, 352],
  [344, 361],
  [344, 371],
  [323],
  [336],
  [336, 1],
  [336, 34],
  [336, 85],
  [336, 129],
  [336, 137],
  [336, 171],
  [336, 152],
  [336, 179],
  [336, 219],
  [336, 292],
  [336, 274],
  [336, 359],
  [336, 376],
  [124, 95],
  [124, 130],
  [124, 216],
  [124, 297],
  [124, 302],
  [124, 351],
  [124, 327],
  [124, 343],
  [124, 350],
  [124, 49, 307],
  [124, 383],
  [342, 59],
  [121, 342],
  [148, 342],
  [342, 137],
  [169, 342],
  [342, 158],
  [366, 342],
  [329],
  [329, 114],
  [329, 179],
  [329, 217],
  [329, 230],
  [329, 266],
  [329, 342],
  [329, 338],
  [329, 380],
  [132, 171],
  [338],
  [349],
  [349, 311],
  [348],
  [348, 194],
  [348, 224],
  [347],
  [247, 152],
  [357],
  [354],
  [354, 39],
  [103, 354],
  [354, 145],
  [354, 67, 1],
  [294, 354],
  [354, 260],
  [354, 313],
  [354, 362],
  [108, 285],
  [237, 152],
  [359],
  [359, 59],
  [48, 359],
  [359, 75],
  [359, 117],
  [359, 128],
  [359, 46, 71],
  [359, 223],
  [359, 292],
  [277, 278],
  [360],
  [362],
  [240, 362],
  [362, 202],
  [316, 180],
  [378],
  [378, 142],
  [374],
  [286, 322],
  [380],
  [380, 121],
  [381],
  [381, 216],
  [381, 297],
  [381, 353],
  [383],
  [106, 383],
  [385],
]
