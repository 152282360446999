export const VALIDGUESSES: string[] = [
  '一',
  '丁',
  '七',
  '丈',
  '三',
  '上',
  '下',
  '不',
  '丐',
  '丑',
  '且',
  '丕',
  '世',
  '丘',
  '丙',
  '丟',
  '並',
  '丫',
  '中',
  '串',
  '丸',
  '丹',
  '主',
  '乃',
  '久',
  '么',
  '之',
  '乍',
  '乎',
  '乏',
  '乒',
  '乓',
  '乙',
  '九',
  '乞',
  '也',
  '了',
  '事',
  '二',
  '于',
  '云',
  '互',
  '五',
  '井',
  '亙',
  '亞',
  '亡',
  '交',
  '亥',
  '亦',
  '人',
  '什',
  '仁',
  '仃',
  '仄',
  '仆',
  '仇',
  '介',
  '仍',
  '仔',
  '仕',
  '他',
  '仗',
  '付',
  '仙',
  '仟',
  '代',
  '以',
  '仲',
  '件',
  '任',
  '企',
  '伊',
  '伍',
  '伏',
  '伐',
  '休',
  '伕',
  '伙',
  '伯',
  '伸',
  '似',
  '佃',
  '位',
  '住',
  '佛',
  '作',
  '佣',
  '使',
  '依',
  '便',
  '俄',
  '俚',
  '信',
  '俳',
  '俾',
  '倀',
  '們',
  '倖',
  '偶',
  '偽',
  '傀',
  '傭',
  '僅',
  '兀',
  '允',
  '元',
  '兄',
  '充',
  '兆',
  '先',
  '免',
  '兒',
  '兕',
  '入',
  '內',
  '全',
  '八',
  '公',
  '六',
  '兮',
  '共',
  '兵',
  '其',
  '具',
  '典',
  '兼',
  '冉',
  '冊',
  '再',
  '冑',
  '冒',
  '冗',
  '冢',
  '冬',
  '冰',
  '准',
  '凍',
  '几',
  '凡',
  '凶',
  '凸',
  '凹',
  '出',
  '刀',
  '刁',
  '刃',
  '分',
  '切',
  '刈',
  '刊',
  '刎',
  '划',
  '列',
  '初',
  '利',
  '刪',
  '到',
  '制',
  '刺',
  '刻',
  '則',
  '剌',
  '劑',
  '力',
  '功',
  '加',
  '助',
  '劾',
  '勒',
  '動',
  '勤',
  '勻',
  '勾',
  '勿',
  '包',
  '匆',
  '匍',
  '匕',
  '化',
  '北',
  '匝',
  '匠',
  '匡',
  '匣',
  '匪',
  '匹',
  '十',
  '千',
  '卅',
  '升',
  '午',
  '卉',
  '半',
  '卑',
  '南',
  '卜',
  '卞',
  '占',
  '卯',
  '印',
  '即',
  '卸',
  '厄',
  '去',
  '又',
  '叉',
  '及',
  '友',
  '反',
  '取',
  '口',
  '古',
  '句',
  '另',
  '叨',
  '叩',
  '只',
  '叫',
  '召',
  '叭',
  '叮',
  '可',
  '台',
  '叱',
  '史',
  '右',
  '叵',
  '吁',
  '各',
  '吆',
  '吉',
  '吊',
  '吋',
  '名',
  '吏',
  '吐',
  '吒',
  '君',
  '吝',
  '吠',
  '否',
  '吧',
  '吶',
  '吸',
  '吻',
  '吾',
  '呀',
  '呆',
  '告',
  '呱',
  '味',
  '呷',
  '呻',
  '呼',
  '咀',
  '咋',
  '和',
  '咦',
  '咪',
  '咱',
  '咳',
  '哂',
  '員',
  '哦',
  '哩',
  '哺',
  '唁',
  '售',
  '唯',
  '唾',
  '啄',
  '問',
  '啞',
  '啡',
  '啤',
  '單',
  '嗎',
  '嗚',
  '嘆',
  '嘈',
  '嘍',
  '嘿',
  '嚨',
  '囚',
  '四',
  '回',
  '因',
  '囤',
  '困',
  '圃',
  '土',
  '圭',
  '圯',
  '地',
  '圳',
  '圾',
  '址',
  '坍',
  '坏',
  '坡',
  '坤',
  '坩',
  '坼',
  '垂',
  '垃',
  '垠',
  '埂',
  '埋',
  '埔',
  '埤',
  '堆',
  '堊',
  '塊',
  '塢',
  '墨',
  '壟',
  '士',
  '壬',
  '壯',
  '壺',
  '夕',
  '外',
  '夙',
  '多',
  '大',
  '天',
  '太',
  '夫',
  '夭',
  '央',
  '失',
  '夷',
  '奄',
  '奈',
  '奉',
  '套',
  '女',
  '奴',
  '奶',
  '奸',
  '她',
  '好',
  '如',
  '妃',
  '妊',
  '妒',
  '妝',
  '妞',
  '妥',
  '妯',
  '妹',
  '妻',
  '妾',
  '姆',
  '姊',
  '姍',
  '姐',
  '姒',
  '姓',
  '委',
  '姜',
  '姨',
  '姪',
  '姬',
  '娌',
  '娥',
  '婁',
  '婢',
  '媽',
  '嫌',
  '嬋',
  '子',
  '孑',
  '孓',
  '孔',
  '孕',
  '字',
  '孚',
  '孜',
  '孝',
  '孟',
  '季',
  '孤',
  '孩',
  '它',
  '宅',
  '宇',
  '守',
  '安',
  '宋',
  '宗',
  '官',
  '宙',
  '宜',
  '室',
  '宦',
  '家',
  '寓',
  '寵',
  '寸',
  '寺',
  '射',
  '專',
  '小',
  '少',
  '尖',
  '尤',
  '尸',
  '尹',
  '尺',
  '尼',
  '尾',
  '尿',
  '屋',
  '屎',
  '屢',
  '屯',
  '山',
  '岌',
  '岩',
  '岫',
  '岳',
  '岷',
  '峨',
  '島',
  '崔',
  '川',
  '州',
  '巡',
  '巢',
  '工',
  '左',
  '巧',
  '巨',
  '己',
  '已',
  '巳',
  '巴',
  '巾',
  '市',
  '布',
  '帕',
  '帛',
  '帳',
  '帶',
  '帷',
  '干',
  '平',
  '年',
  '并',
  '幸',
  '幻',
  '幼',
  '床',
  '庚',
  '庠',
  '庫',
  '康',
  '庸',
  '庾',
  '廉',
  '廳',
  '建',
  '廿',
  '弁',
  '弄',
  '式',
  '弓',
  '弔',
  '引',
  '弗',
  '弘',
  '弛',
  '弧',
  '弭',
  '張',
  '彈',
  '彤',
  '彼',
  '彿',
  '往',
  '很',
  '律',
  '徘',
  '心',
  '必',
  '忌',
  '忖',
  '志',
  '忠',
  '快',
  '忱',
  '忽',
  '怎',
  '怏',
  '怕',
  '思',
  '性',
  '恆',
  '恙',
  '恥',
  '恨',
  '息',
  '悉',
  '悚',
  '患',
  '悲',
  '悵',
  '悶',
  '悽',
  '惟',
  '惠',
  '惡',
  '愚',
  '愧',
  '憚',
  '戈',
  '戊',
  '戌',
  '戍',
  '戎',
  '成',
  '我',
  '戒',
  '戕',
  '戰',
  '戶',
  '戾',
  '所',
  '扁',
  '扇',
  '扉',
  '手',
  '才',
  '扎',
  '扒',
  '打',
  '扔',
  '托',
  '扛',
  '扣',
  '扭',
  '扯',
  '扶',
  '找',
  '承',
  '抉',
  '把',
  '抓',
  '折',
  '披',
  '抹',
  '押',
  '抽',
  '抿',
  '拂',
  '拄',
  '拆',
  '拇',
  '拉',
  '拍',
  '拓',
  '拽',
  '捕',
  '捫',
  '捶',
  '捷',
  '排',
  '推',
  '摟',
  '擠',
  '攏',
  '支',
  '收',
  '改',
  '攻',
  '救',
  '敗',
  '敝',
  '敢',
  '數',
  '文',
  '斐',
  '斗',
  '斤',
  '斥',
  '斧',
  '斫',
  '斬',
  '方',
  '既',
  '日',
  '旦',
  '旨',
  '早',
  '旬',
  '旭',
  '旱',
  '旺',
  '昌',
  '明',
  '昏',
  '易',
  '昔',
  '星',
  '映',
  '春',
  '昧',
  '昨',
  '晒',
  '晝',
  '曦',
  '曰',
  '曲',
  '曳',
  '更',
  '書',
  '曹',
  '月',
  '有',
  '朋',
  '朧',
  '木',
  '未',
  '末',
  '本',
  '札',
  '朮',
  '朱',
  '朴',
  '朵',
  '朽',
  '杉',
  '李',
  '杏',
  '材',
  '村',
  '杖',
  '杜',
  '杞',
  '束',
  '杯',
  '杰',
  '東',
  '杳',
  '杵',
  '杷',
  '枉',
  '析',
  '枕',
  '林',
  '枚',
  '果',
  '柄',
  '柏',
  '某',
  '柑',
  '柔',
  '柚',
  '柞',
  '柬',
  '柱',
  '柵',
  '栗',
  '栩',
  '核',
  '根',
  '梗',
  '梟',
  '棄',
  '棗',
  '棘',
  '棟',
  '棣',
  '棲',
  '棵',
  '椎',
  '榷',
  '槐',
  '槽',
  '樓',
  '欠',
  '止',
  '正',
  '此',
  '步',
  '歹',
  '死',
  '殃',
  '毋',
  '母',
  '每',
  '毒',
  '比',
  '毛',
  '氏',
  '氐',
  '民',
  '氖',
  '氟',
  '氣',
  '氦',
  '氧',
  '水',
  '永',
  '氾',
  '汀',
  '汁',
  '求',
  '汐',
  '汕',
  '汗',
  '汙',
  '汝',
  '汞',
  '江',
  '池',
  '汨',
  '汪',
  '汲',
  '決',
  '汽',
  '沁',
  '沈',
  '沌',
  '沐',
  '沖',
  '沛',
  '沫',
  '沮',
  '沸',
  '油',
  '泄',
  '泉',
  '泊',
  '泌',
  '波',
  '泣',
  '注',
  '泰',
  '泱',
  '泳',
  '洋',
  '津',
  '洱',
  '洲',
  '浦',
  '浬',
  '淒',
  '淮',
  '滯',
  '漕',
  '漢',
  '濟',
  '火',
  '灰',
  '灶',
  '灸',
  '災',
  '炎',
  '炙',
  '炳',
  '炸',
  '為',
  '烊',
  '烏',
  '無',
  '焦',
  '燻',
  '爪',
  '爬',
  '爭',
  '父',
  '爸',
  '爻',
  '片',
  '牌',
  '牙',
  '牛',
  '牝',
  '牟',
  '牠',
  '牡',
  '牢',
  '牧',
  '物',
  '牲',
  '牽',
  '犧',
  '犬',
  '犯',
  '狀',
  '狂',
  '狄',
  '狎',
  '狐',
  '狙',
  '狠',
  '狸',
  '狽',
  '猓',
  '玄',
  '玉',
  '王',
  '玖',
  '玟',
  '玨',
  '玫',
  '玻',
  '珀',
  '珊',
  '現',
  '球',
  '理',
  '琢',
  '瑪',
  '瑰',
  '瓏',
  '瓜',
  '瓦',
  '甘',
  '生',
  '甦',
  '用',
  '甩',
  '甫',
  '甭',
  '田',
  '由',
  '甲',
  '申',
  '男',
  '甸',
  '甽',
  '畢',
  '番',
  '疋',
  '疚',
  '疝',
  '疤',
  '疲',
  '疳',
  '疽',
  '病',
  '痕',
  '痱',
  '痺',
  '白',
  '百',
  '皂',
  '皇',
  '皮',
  '皿',
  '盂',
  '盃',
  '盎',
  '目',
  '盯',
  '直',
  '相',
  '盹',
  '眉',
  '看',
  '眠',
  '眼',
  '睡',
  '睥',
  '睫',
  '矓',
  '矛',
  '矢',
  '石',
  '矽',
  '破',
  '砷',
  '硬',
  '硯',
  '碑',
  '碘',
  '確',
  '碼',
  '示',
  '社',
  '祀',
  '祁',
  '祇',
  '祈',
  '祉',
  '祕',
  '祖',
  '祚',
  '神',
  '祥',
  '票',
  '禪',
  '禹',
  '禾',
  '禿',
  '秀',
  '私',
  '秉',
  '秋',
  '租',
  '秣',
  '秦',
  '秧',
  '秩',
  '稚',
  '種',
  '穴',
  '立',
  '童',
  '竹',
  '竺',
  '竽',
  '竿',
  '笆',
  '笙',
  '笛',
  '笠',
  '笨',
  '筆',
  '策',
  '篤',
  '簍',
  '簞',
  '籠',
  '米',
  '粒',
  '粗',
  '粟',
  '粳',
  '糟',
  '糸',
  '系',
  '糾',
  '紀',
  '紂',
  '紅',
  '紊',
  '紋',
  '納',
  '紐',
  '純',
  '紙',
  '級',
  '素',
  '累',
  '細',
  '紳',
  '紼',
  '組',
  '絲',
  '綞',
  '維',
  '緬',
  '縑',
  '縷',
  '繩',
  '缶',
  '缸',
  '缺',
  '缽',
  '罪',
  '罵',
  '羊',
  '羌',
  '美',
  '羔',
  '義',
  '羲',
  '羽',
  '羿',
  '翌',
  '習',
  '翔',
  '翟',
  '翡',
  '老',
  '考',
  '者',
  '而',
  '耍',
  '耐',
  '耒',
  '耕',
  '耗',
  '耙',
  '耜',
  '耳',
  '耶',
  '耽',
  '耿',
  '聖',
  '聞',
  '聾',
  '聿',
  '肅',
  '肆',
  '肉',
  '肋',
  '肌',
  '肖',
  '肘',
  '肚',
  '肛',
  '肝',
  '肥',
  '肩',
  '肫',
  '肯',
  '育',
  '肺',
  '胃',
  '胄',
  '胛',
  '胰',
  '脯',
  '脹',
  '脾',
  '腆',
  '腫',
  '臍',
  '臣',
  '臥',
  '自',
  '臬',
  '臭',
  '至',
  '致',
  '臼',
  '臾',
  '舀',
  '舂',
  '舌',
  '舛',
  '舟',
  '舢',
  '舶',
  '艮',
  '良',
  '艱',
  '色',
  '艾',
  '芋',
  '芙',
  '芝',
  '芭',
  '芹',
  '芽',
  '苒',
  '苗',
  '苜',
  '英',
  '茂',
  '茅',
  '茉',
  '茸',
  '荏',
  '菲',
  '萋',
  '萬',
  '萸',
  '董',
  '蒐',
  '薰',
  '虎',
  '虐',
  '虔',
  '虛',
  '虫',
  '虹',
  '蚊',
  '蚌',
  '蚯',
  '蚱',
  '蚶',
  '蛆',
  '蛭',
  '蛾',
  '蝕',
  '螞',
  '螻',
  '蟬',
  '蠅',
  '血',
  '行',
  '衍',
  '街',
  '衙',
  '衛',
  '衝',
  '衡',
  '衢',
  '衣',
  '表',
  '衫',
  '袂',
  '袖',
  '被',
  '裊',
  '裘',
  '補',
  '裡',
  '裨',
  '裴',
  '裸',
  '褸',
  '襲',
  '西',
  '要',
  '見',
  '規',
  '覓',
  '視',
  '覲',
  '言',
  '訂',
  '訃',
  '計',
  '訌',
  '討',
  '訐',
  '訓',
  '訕',
  '託',
  '記',
  '訝',
  '訣',
  '訥',
  '許',
  '詐',
  '詛',
  '詠',
  '該',
  '詳',
  '誅',
  '誰',
  '課',
  '謙',
  '謹',
  '豕',
  '豚',
  '豺',
  '貍',
  '貝',
  '貞',
  '負',
  '財',
  '貢',
  '貫',
  '責',
  '買',
  '費',
  '賃',
  '賅',
  '賈',
  '賬',
  '賺',
  '赤',
  '走',
  '足',
  '跌',
  '身',
  '躬',
  '車',
  '軋',
  '軌',
  '軍',
  '軒',
  '軸',
  '軼',
  '輊',
  '輔',
  '輩',
  '辛',
  '迂',
  '迆',
  '近',
  '迪',
  '迫',
  '迷',
  '迺',
  '退',
  '逐',
  '這',
  '速',
  '連',
  '逮',
  '進',
  '遇',
  '遭',
  '邑',
  '邦',
  '邪',
  '邱',
  '郵',
  '酉',
  '酊',
  '酋',
  '配',
  '酒',
  '酣',
  '酥',
  '酬',
  '醜',
  '醺',
  '采',
  '里',
  '重',
  '金',
  '釗',
  '釘',
  '針',
  '釦',
  '釧',
  '鈉',
  '鈍',
  '鈕',
  '鈣',
  '鈾',
  '鉀',
  '鉑',
  '鉗',
  '銀',
  '銜',
  '鋪',
  '錐',
  '錘',
  '鍾',
  '鎢',
  '鏤',
  '長',
  '門',
  '閂',
  '閃',
  '閉',
  '閏',
  '閑',
  '閒',
  '間',
  '閔',
  '閘',
  '閡',
  '閩',
  '闖',
  '闡',
  '阡',
  '阱',
  '阻',
  '限',
  '陣',
  '陲',
  '陳',
  '陴',
  '隅',
  '隊',
  '隴',
  '隻',
  '雀',
  '雅',
  '集',
  '雇',
  '雋',
  '難',
  '雨',
  '雪',
  '雯',
  '雷',
  '電',
  '需',
  '霍',
  '霏',
  '霽',
  '青',
  '非',
  '面',
  '靦',
  '革',
  '靶',
  '鞅',
  '韭',
  '音',
  '飛',
  '食',
  '飢',
  '飧',
  '飩',
  '飪',
  '養',
  '餌',
  '餓',
  '餽',
  '首',
  '香',
  '馬',
  '馭',
  '馮',
  '馱',
  '馳',
  '馴',
  '駐',
  '駛',
  '駭',
  '骨',
  '鬥',
  '鬼',
  '魄',
  '魅',
  '鳥',
  '黑',
  '默',
  '鼎',
  '鼠',
  '齊',
  '齋',
  '齒',
  '齟',
  '齦',
  '齲',
  '龍',
  '龐',
  '龜',
]
